import React from 'react';

const style = {
  display: 'block',
};

const ModalBackdrop = () => {
  return (
    <div
      className="modal-backdrop modal-backdrop--theme--primary"
      aria-hidden="true"
      style={style}
    />
  );
};

export default ModalBackdrop;
