import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Portal from 'jsfcore/components/Portal';
import { popoverThemes } from 'jsfcore/ui/Popover/Popover';
import PopoverBody from 'jsfcore/ui/Popover/PopoverBody';
import * as Group from 'jsfcore/ui/Group';
import { groupOffsets } from 'jsfcore/ui/Group/Group';
import attributes from 'jsfcore/ui/attributes';
import Text, {
  textFamilies,
  textLineHeights,
  textSizes,
  textThemes,
} from 'jsfcore/ui/Text';
import Control, { controlSizes, controlThemes } from 'jsfcore/ui/Control';
import Icon, { iconSizes, iconThemes, iconTypes } from 'jsfcore/ui/Icon';

const SUPPORT_BUTTON_HINT_VERTICAL_OFFSET = 10;
const locatorArgs = {
  position: Portal.wrapperPositions.topRight,
  preset: Portal.wrapperPresets.hint,
  verticalOffset: SUPPORT_BUTTON_HINT_VERTICAL_OFFSET,
};

const SupportChatButtonHint = (props) => {
  const {
    hintMessage,
    closeHintButtonTitle,
  } = props;

  return (
    <Portal.VisibilityProvider initialVisibility>
      {({ closePortal, isVisible }) => {
        if (!isVisible) {
          return <Fragment />;
        }
        return (
          <Portal.WrapperExperiment
            getAnchorRefPromise={props.getAnchorRefPromise}
            locatorArgs={locatorArgs}
            theme={popoverThemes.white}
            zIndex={119}
            useArrow
          >
            <PopoverBody>
              <Group.Index offset={groupOffsets.extraLarge}>
                <Group.Body>
                  <Group.Item attributes={attributes.grow}>
                    <Text
                      size={textSizes[14]}
                      theme={textThemes.dovegray}
                      family={textFamilies.openSans}
                      lineHeight={textLineHeights.medium}
                      html={hintMessage}
                    />
                  </Group.Item>
                  <Group.Item>
                    <Control
                      size={controlSizes.parent}
                      theme={controlThemes.transparent}
                      title={closeHintButtonTitle}
                      icon={
                        <Icon
                          type={iconTypes.redesignClose}
                          theme={iconThemes.lightgray}
                          size={iconSizes.medium}
                        />
                      }
                      onClick={closePortal}
                    />
                  </Group.Item>
                </Group.Body>
              </Group.Index>
            </PopoverBody>
          </Portal.WrapperExperiment>
        );
      }}
    </Portal.VisibilityProvider>
  );
};

SupportChatButtonHint.propTypes = {
  hintMessage: PropTypes.string.isRequired,
  closeHintButtonTitle: PropTypes.string.isRequired,
  getAnchorRefPromise: PropTypes.func.isRequired,
};

export default SupportChatButtonHint;
