import PropTypes from 'prop-types';
import React from 'react';

const tryNowView = ({
  onClickCancel,
  onClickTry,
  email,
  accessTimer,
  locale,
  autoCheck,
  disabled,
}) => {
  return (
    <div className="document-loader-overlay">
      <div className="document-loader">
        <div className="document-loader__title">{locale.title}</div>
        <div className="document-loader-row">
          <div className="document-loader-row_cell document-loader-row_cell--img">
            <div className="document-loader__img document-loader__img--loading" />
            <div className="document-loader__title document-loader__title--mobile">
              {locale.title}
            </div>
          </div>
          <div className="document-loader-row_cell">
            <p>
              {locale.editedBy}
              <br />
              <b>{email}</b>
            </p>
            {autoCheck &&
              <p>
                {locale.beforeTimer} <b>{accessTimer} </b>
                {locale.afterTimer}
              </p>
            }
          </div>
        </div>
        <div className="document-loader-action-btn">
          <button
            type="button"
            onClick={onClickCancel}
            disabled={disabled}
            className="g-btn g-btn g-btn-secondary g-btn-auto-width"
          >
            {locale.cancel}
          </button>
          <button
            type="button"
            onClick={onClickTry}
            disabled={disabled}
            className="g-btn g-btn-primary g-btn-auto-width"
          >
            {locale.tryNow}
          </button>
        </div>
      </div>
    </div>
  );
};

tryNowView.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onClickTry: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  accessTimer: PropTypes.number.isRequired,
  autoCheck: PropTypes.bool.isRequired,
  locale: PropTypes.shape({
    title: PropTypes.string.isRequired,
    editedBy: PropTypes.string.isRequired,
    beforeTimer: PropTypes.string.isRequired,
    afterTimer: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    tryNow: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default tryNowView;
