import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { setActivePage } from 'jsfcore/store/modules/navigation';
import { selectors } from 'jsfcore';

import { renderScenario, ifApply } from '../scenariosUtils';

// Default Page (Show)

// "action": "default_page.show",  идентификатор действия: активировать требуемую страницу после
// ее загрузки
// "apply": true,  применять ли данное действие
// "runOnce": true,  запустить единоразово
// "delay": 0,  задержка перед выполнением, (ms)
// "onShow":[]  событие показа указанной страницы, по которому запускается вложенная цепочка
// действий

// "params":{}  объект содержит такие свойства: "pageId": 1 - страница отображаемая по
// умолчанию при загрузке эдитора.
// "pageId": 1,  какую страницу показать (если она еще не загружена - ожидать). тут идет
// речь именно об индексе страницы в PDF, а не ее текущей позиции в документе. Чаще
// всего они совпадают

const defaultState = {
  isScenarioStarted: false,
  isScenarioFinished: false,
};

const isPageIdHasCorrectPositionInPagesSettings =
  ({ pagesSettings, pageId }) => {
    return pagesSettings.findIndex((page) => {
      return page.source === pageId;
    }) === pageId;
  };

@connect((state) => {
  return {
    canvasesTimestamp: state.pdf.canvasesTimestamp,
    pagesSettings: selectors.navigation.getPagesSettings(state),
    havePdfDocument: state.pdf.hasPdfDocument,
  };
}, {
  setActivePage,
})
export default class DefaultPageShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['default_page.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      pageId: ifApply(PropTypes.number),
    }).isRequired,
    onShow: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    pagesSettings: PropTypes.arrayOf(
      PropTypes.shape({
        rotation: PropTypes.number,
        source: PropTypes.number,
        visible: PropTypes.bool,
      }),
    ),

    havePdfDocument: PropTypes.bool.isRequired,
    setActivePage: PropTypes.func.isRequired,

    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    canvasesTimestamp: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.bool,
    ]).isRequired,
  };

  static defaultProps = {
    pagesSettings: [],
  };

  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { havePdfDocument, pagesSettings } = this.props;

    if (havePdfDocument) {
      this.run(pagesSettings);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { havePdfDocument, pagesSettings, canvasesTimestamp, params } = nextProps;
    if (havePdfDocument && !this.props.havePdfDocument) {
      this.run(pagesSettings);
    }

    if (this.state.isScenarioStarted && !this.state.isScenarioFinished) {
      const isScenarioFinished =
        canvasesTimestamp &&
        canvasesTimestamp.length &&
        !!canvasesTimestamp[params.pageId];
      if (!this.state.isScenarioFinished && isScenarioFinished) {
        this.setState({ isScenarioFinished: true });
      }
    }
  }

  run = (pagesSettings) => {
    const { params, apply, runOnce } = this.props;
    const { pageId } = params;

    if ((runOnce && this.state.isScenarioFinished) || !apply) {
      return;
    }

    const fun = () => {
      const settingsOfScenarioPage = find(
        pagesSettings, ({ source }) => {
          return source === pageId;
        },
      );

      if (settingsOfScenarioPage && settingsOfScenarioPage.visible) {
        // TODO 30.09.2018 need push Kiev about problem with incorrect scenarios pageId
        // and document after rearrange
        if (isPageIdHasCorrectPositionInPagesSettings({ pagesSettings, pageId })) {
          this.props.setActivePage(pageId, { scenario: true });
        } else {
          const pageOnZeroPosition = pagesSettings[0];

          if (pageOnZeroPosition && pageOnZeroPosition.visible) {
            this.props.setActivePage(pageOnZeroPosition.source, { scenario: true });
          }
        }
      }

      this.setState({
        ...defaultState,
        isScenarioStarted: true,
      });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  };

  render() {
    const { onShow } = this.props;

    if (this.state.isScenarioFinished && onShow.length > 0) {
      return (
        <div>
          {onShow.map(renderScenario)}
        </div>
      );
    }

    return null;
  }
}
