import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rearrange, dispatchAction } from '@pdffiller/jsf-lazyload';
import { selectors } from 'jsfcore';
import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';

import { renderScenario } from '../scenariosUtils';

// Rearrange show
// "action": "rearrange.show" идентификатор действия:
//   открыть Rearrange диалог, если он доступен в данном режиме.
// "params":{} без параметров
// "onShow":[] событие открытия Rearrange, по которому запускается вложенная цепочка действий,
// которые будут доступны только в пределах Rearrange. пока не предусмотрено.

@connect(
  (state) => {
    return {
      isRearrangeLoaded: lazySelectors.jsf.getIsRearrangeLoaded(state),
      isRearrangeAvailable: selectors.getIsRearrangeAvailable(state),
    };
  },
)
export default class RearrangeShow extends Component {
  static propTypes = {
    isRearrangeLoaded: PropTypes.bool.isRequired,
    isRearrangeAvailable: PropTypes.bool.isRequired,

    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onShow: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  };

  static defaultProps = {
    onShow: [],
  };

  state = { isShowed: false };

  componentDidMount() {
    const { apply, isRearrangeLoaded, isRearrangeAvailable } = this.props;
    if (!apply || !isRearrangeAvailable) {
      return;
    }

    if (isRearrangeLoaded) {
      this.openRearrange();
    }
  }

  componentDidUpdate() {
    if (
      this.props.apply &&
      !this.state.isShowed &&
      this.props.isRearrangeLoaded &&
      this.props.isRearrangeAvailable
    ) {
      this.openRearrange();
    }
  }

  openRearrange = () => {
    const fun = () => {
      dispatchAction(rearrange.actions.openPageRearrange);
      this.setState({ isShowed: true });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    const { onShow } = this.props;
    if (this.state.isShowed && onShow && onShow.length > 0) {
      return (
        <div>
          {onShow.map(renderScenario)}
        </div>
      );
    }

    return null;
  }
}
