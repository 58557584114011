import { useEffect } from 'react';
import getTitle from 'jsfcore/helpers/getTitle';

function Title({ title }) {
  useEffect(() => {
    if (!__TEST__ && !__INTEGRATION_TEST__) {
      document.querySelector('title').innerText = getTitle(title);
    }
  }, [title]);
  return null;
}

export default Title;
