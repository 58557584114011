import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const interfaceLocker = ({ busyCursor }) => {
  return (
    <div
      className={classnames('interfaceLocker', { busyCursor })}
    />
  );
};

interfaceLocker.propTypes = {
  busyCursor: PropTypes.bool,
};

interfaceLocker.defaultProps = {
  busyCursor: false,
};

export default interfaceLocker;
