import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MESSAGE_MODAL } from 'combine-modals/lib/constants/modal.classnames';

import { showMessageButton } from 'jsfcore/store/modules/viewport';
import { renderScenario } from '../scenariosUtils';

@connect(
  (state) => {
    return {
      triggerMessageButtonClick: state.events.triggerMessageButtonClick,
      isMessageModalOpened: state.modal.options.hasOwnProperty(MESSAGE_MODAL),
    };
  }, {
    showMessageButton,
  },
)
export default class MessageButtonShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['message.button.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onClick: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,

    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    isMessageModalOpened: PropTypes.bool.isRequired,

    triggerMessageButtonClick: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]).isRequired,
    showMessageButton: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { clickStack: [] };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.showMessageButton();
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ triggerMessageButtonClick, isMessageModalOpened }) {
    if (
      triggerMessageButtonClick !== this.props.triggerMessageButtonClick &&
      !isMessageModalOpened
    ) {
      this.setState((prevState) => {
        return {
          clickStack: [
            ...prevState.clickStack,
            ...this.props.onClick,
          ],
        };
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.clickStack.map(renderScenario)}
      </div>
    );
  }
}
