import React from 'react';

const ConnectionLostIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="145" height="40" viewBox="-2 -2 145 40">
      <g fill="none" fillRule="evenodd">
        <path fill="#DDD" d="M25 17h87v2H25z" />
        <rect fill="#444" x="3" width="34" height="23" rx="2" />
        <path fill="#DDD" d="M5 2h30v19H5z" />
        <path fill="#FFF" d="M5 2h30v5H5z" />
        <path fill="#EA6753" d="M6 3h3L7.5 6z" />
        <path fill="#909090" d="M10 4h3v1h-3zm5-1h2v3h-2zm3 0h2v3h-2zm3 0h2v3h-2zm3 0h2v3h-2zm3 0h2v3h-2z" />
        <path fill="#FFF" d="M7 9h26v12H7z" />
        <path fill="#909090" d="M10 12h20v1H10zm0 2h20v1H10zm0 2h20v1H10zm0 2h20v1H10z" />
        <path fill="#444" d="M0 31h40v5H0zm3.015-8h33.968l2.986 8H.092z" />
        <path fill="#C6CAEA" d="M5.04 23h29.945L38 31H2zM2 33h36l-.018.986L2 34z" />
        <path
          d="M68.02 17.98L63 23l1.98 1.98L70 19.96l5.02 5.02L77 23l-5.02-5.02 4.88-4.88-1.98-1.98L70 16l-4.88-4.88-1.98 1.98 4.88 4.88z"
          fill="#FD704A"
        />
        <path
          d="M116.291 16c.55 0 1.233.388 1.519.853l2.126 3.46c.29.47.752.47 1.035-.006l3.842-6.449c.283-.474.062-.858-.487-.858h-22.685c-.551 0-1.226-.39-1.504-.863L93.523.863c-.28-.477-.057-.863.502-.863h37.967c.557 0 1.243.383 1.528.849l6.531 10.651c.288.469.29 1.24.014 1.705l-12.991 21.939c-.28.473-.946.856-1.517.856H115.24c-.558 0-1.234-.382-1.515-.86L102.98 16.86c-.279-.475-.061-.86.49-.86h12.82z"
          fill="#444"
        />
        <path fill="#909090" d="M96.745 2h34.929l-5.294 9h-24.307z" />
        <path fill="#EA6753" d="M133.14 3.974l4.925 8.353L125.325 34h-9.842z" />
        <path fill="#F78776" d="M116.047 18l3.182 5.306-4.954 8.357L106.223 18z" />
      </g>
    </svg>
  );
};

export default ConnectionLostIcon;
