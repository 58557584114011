import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getModeIdFromState } from 'ws-editor-lib/store/helpers';
import { DEFAULT_EDITOR_MODE } from 'ws-editor-lib/constants';
import { getHelpModalLocale } from 'jsfcore/store/selectors/localesSelectors';

import { openCoreHelpModal } from 'combine-modals/lib/actions/actionCreator';
import { renderScenario } from '../scenariosUtils';

@connect(
  (state) => {
    return {
      helpModalTitle: getHelpModalLocale(state).title,
      editorModeId: getModeIdFromState(state),
    };
  }, {
    openCoreHelpModal,
  },
)
export default class WelcomeHelp extends Component {
  static propTypes = {
    apply: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onHide: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    params: PropTypes.shape({}),
    helpModalTitle: PropTypes.string.isRequired,
    editorModeId: PropTypes.string.isRequired,

    openCoreHelpModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    params: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isWelcomeHelpHidden: false,
      onHide: props.onHide,
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      const { helpModalTitle, editorModeId } = this.props;

      this.props.openCoreHelpModal({
        welcome: helpModalTitle,
        editorModeId,
        mode: DEFAULT_EDITOR_MODE,
        isResponsive: true,

        onHide: this.onHide,
      });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  onHide = (params = {}) => {
    this.setState((prevState) => {
      return {
        isWelcomeHelpHidden: true,
        onHide: prevState.onHide.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  render() {
    const { onHide } = this.state;
    if (this.state.isWelcomeHelpHidden && onHide.length > 0) {
      return (<div>{onHide.map(renderScenario)}</div>);
    }

    return null;
  }
}
