import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text, { textSizes } from 'jsfcore/ui/Text';
import Icon, { iconTypes, iconSizes } from 'jsfcore/ui/Icon';
import Control, { controlSizes, controlThemes } from 'jsfcore/ui/Control';
import Popover, {
  popoverThemes,
  popoverArrowPositions,
  popoverSizes,
  popoverOffsets,
} from 'jsfcore/ui/Popover/Popover';
import PopoverBody from 'jsfcore/ui/Popover/PopoverBody';

const popoverStyle = {
  marginLeft: -120,
  position: 'absolute',
  left: '50%',
  bottom: 'calc(100% + 7px)',
  maxWidth: '240px',
  minHeight: '23px',
};

const helpStyle = {
  position: 'relative',
};

export default class InputFieldSetHeader extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    helpTooltipText: PropTypes.string,
    hasHelp: PropTypes.bool,
    className: PropTypes.string,

    onHelpMouseOut: PropTypes.func,
    onHelpMouseOver: PropTypes.func,
  };

  static defaultProps = {
    helpTooltipText: null,
    hasHelp: false,
    className: null,

    onHelpMouseOut: null,
    onHelpMouseOver: null,
  };

  render() {
    const { className } = this.props;

    return (
      <div
        className={cx(
          'input-fieldset__header', {
            [className]: className,
          },
        )}
      >
        <span className="input-fieldset__text">
          {this.props.children}
        </span>

        {this.props.hasHelp && (
          <span
            className="input-fieldset__help"
            style={helpStyle}
            onMouseOut={this.props.onHelpMouseOut}
            onMouseOver={this.props.onHelpMouseOver}
          >
            {this.props.helpTooltipText && (
              <Popover
                theme={popoverThemes.dark}
                arrowPosition={popoverArrowPositions.bottomCenter}
                size={popoverSizes.small}
                offset={popoverOffsets.small}
                style={popoverStyle}
              >
                <PopoverBody>
                  <Text
                    center
                    size={textSizes[12]}
                  >
                    {this.props.helpTooltipText}
                  </Text>
                </PopoverBody>
              </Popover>
            )}

            <Control
              size={controlSizes.parent}
              theme={controlThemes.transparent}
              icon={
                <Icon
                  type={iconTypes.help}
                  size={iconSizes.small}
                />
              }
              ariaLabel="Help control"
              isBusy={false}
              isPressed={false}
              inheritRadius
            />
          </span>
        )}
      </div>
    );
  }
}
