import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DetectRTC from 'detectrtc';
import {
  showPhotoAuthDialog,
} from 'combine-modals/lib/actions/actionCreator';
import { renderScenario } from '../scenariosUtils';

@connect(
  null, {
    showPhotoAuthDialog,
  },
)
export default class PhotoAuthDialogShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['photo_auth.dialog.show']).isRequired,
    // condition: PropTypes.oneOf([
    //   'IF_CAMERA_AVAILABLE',
    // ]).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({}).isRequired,
    onPhoto: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    onSkip: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,

    showPhotoAuthDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      photed: false,
      skipped: false,
      onPhoto: props.onPhoto,
      onSkip: props.onSkip,
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      this.onSkip();
      return;
    }

    const fun = () => {
      if (this.cameraAvaibalbe()) {
        this.props.showPhotoAuthDialog({
          params: this.props.params,
          onPhoto: this.onPhoto,
          onSkip: this.onSkip,
        });
      } else {
        this.onSkip();
      }
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  cameraAvaibalbe = () => {
    return DetectRTC.hasWebcam;
  };

  onPhoto = (params = {}) => {
    this.setState((prevState) => {
      return {
        photed: true,
        onPhoto: prevState.onPhoto.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  onSkip = (params = {}) => {
    this.setState((prevState) => {
      return {
        skipped: true,
        onSkip: prevState.onSkip.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  render() {
    return (
      <div>
        <div>{this.state.photed && this.state.onPhoto.map(renderScenario)}</div>
        <div>{this.state.skipped && this.state.onSkip.map(renderScenario)}</div>
      </div>
    );
  }
}
