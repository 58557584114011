import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showFinalizationDialog,
} from 'combine-modals/lib/actions/actionCreator';
import { renderScenario } from '../scenariosUtils';

@connect(
  null, {
    showFinalizationDialog,
  },
)
export default class RequiredDialogShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['finalization.dialog.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({}).isRequired,
    onSave: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    onFinish: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,

    showFinalizationDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      saved: false,
      finished: false,
      onSave: props.onSave,
      onFinish: props.onFinish,
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.showFinalizationDialog({
        params: this.props.params,
        onSave: this.onSave,
        onFinish: this.onFinish,
      });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  onSave = (params = {}) => {
    this.setState((prevState) => {
      return {
        saved: true,
        onSave: prevState.onSave.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  onFinish = (params = {}) => {
    this.setState((prevState) => {
      return {
        finished: true,
        onFinish: prevState.onFinish.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  render() {
    return (
      <div>
        <div>{this.state.saved && this.state.onSave.map(renderScenario)}</div>
        <div>{this.state.finished && this.state.onFinish.map(renderScenario)}</div>
      </div>
    );
  }
}
