import PropTypes from 'prop-types';
import React from 'react';

function Error(props) {
  return (
    <div className="notification notification-error">
      <div className="notification-title">Error</div>
      <div
        className="notification-message"
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: props.message }}
      />
      <div className="notification-dismiss" onClick={props.onClick}>×</div>
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Error;
