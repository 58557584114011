import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InputFieldSetAdditionalInfo extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className="input-fieldset__additional-info">
        {this.props.children}
      </div>
    );
  }
}
