import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  showFindAnotherFormButton,
  showFindAnotherFormBubble,
  hideFindAnotherFormButton,
  hideFindAnotherFormBubble,
} from 'jsfcore/store/modules/viewport';

import { selectors } from 'jsfcore';

import ScenariosComponentsMapContext from './ScenariosComponentsMapContext';
import { renderScenario } from './scenariosUtils';

@connect(
  (state) => {
    return {
      triggerOnDoneScenario: state.events.triggerOnDoneScenario,
      isFConstructorShown: state.viewport.isFConstructorShown,
      isModalChildsSet: selectors.getIsModalChildsSet(state),
    };
  }, {
    showFindAnotherFormButton,
    showFindAnotherFormBubble,
    hideFindAnotherFormButton,
    hideFindAnotherFormBubble,
  },
)
export default class Scenarios extends Component {
  static propTypes = {
    scenarios: PropTypes.shape({
      onDone: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
      onConstructorExit: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
      onStart: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
    }).isRequired,
    triggerOnDoneScenario: PropTypes.shape({}).isRequired,
    isFConstructorShown: PropTypes.bool.isRequired,
    isModalChildsSet: PropTypes.bool.isRequired,

    //
    showFindAnotherFormButton: PropTypes.func.isRequired,
    showFindAnotherFormBubble: PropTypes.func.isRequired,
    hideFindAnotherFormButton: PropTypes.func.isRequired,
    hideFindAnotherFormBubble: PropTypes.func.isRequired,
  };

  static contextType = ScenariosComponentsMapContext;

  static childContextTypes = {
    showFindAnotherFormButton: PropTypes.func.isRequired,
    showFindAnotherFormBubble: PropTypes.func.isRequired,
    hideFindAnotherFormButton: PropTypes.func.isRequired,
    hideFindAnotherFormBubble: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      onDoneStack: [],
      onConstructorExitStack: [],
    };
  }

  getChildContext = () => {
    return {
      showFindAnotherFormButton: this.props.showFindAnotherFormButton,
      showFindAnotherFormBubble: this.props.showFindAnotherFormBubble,
      hideFindAnotherFormButton: this.props.hideFindAnotherFormButton,
      hideFindAnotherFormBubble: this.props.hideFindAnotherFormBubble,
    };
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ triggerOnDoneScenario, isFConstructorShown }) {
    if (triggerOnDoneScenario !== this.props.triggerOnDoneScenario) {
      const onDone = this.props.scenarios.onDone || [];
      this.setState((prevState) => {
        return {
          onDoneStack: [...prevState.onDoneStack, ...onDone],
        };
      });
    }

    if (this.props.isFConstructorShown && !isFConstructorShown) {
      const onConstructorExit = this.props.scenarios.onConstructorExit || [];
      this.setState((prevState) => {
        return {
          onConstructorExitStack: [...prevState.onConstructorExitStack, ...onConstructorExit],
        };
      });
    }
  }

  render() {
    const { onStart } = this.props.scenarios;
    if (!(onStart && this.props.isModalChildsSet)) {
      return null;
    }

    return (
      <div>
        <div>{onStart.map(renderScenario)}</div>
        <div>{this.state.onConstructorExitStack.map(renderScenario)}</div>
        <div>{this.state.onDoneStack.map(renderScenario)}</div>
      </div>
    );
  }
}
