import PropTypes from 'prop-types';
import React from 'react';
import ConnectionLostIcon from './ConnectionLostIcon';

const connectionLostStyles = {
  zIndex: 1000,
};

const ConnectionLostView = ({ locale, onClickRetry, showRetry }) => {
  return (
    <div className="modal-jsf" style={connectionLostStyles}>
      <div className="modal-no-connect">
        <div className="modal-container">
          <div className="modal-body">
            <ConnectionLostIcon />
            <div className="modal-no-connect__title">{locale.title}</div>
            <div
              className="modal-no-connect__description"
              // Это ок, т.к. локаль приходит с сервера и пользователь
              // не может ее сетить
              //
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: locale.description }}
            />
            {showRetry &&
            <div className="text-center">
              <div
                className="g-btn g-btn-sm g-btn-primary g-btn-auto-width"
                onClick={onClickRetry}
              >
                {locale.retry}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

ConnectionLostView.propTypes = {
  locale: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    retry: PropTypes.string.isRequired,
  }).isRequired,
  showRetry: PropTypes.bool.isRequired,
  onClickRetry: PropTypes.func.isRequired,
};

export default ConnectionLostView;
