import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateElement } from 'ws-editor-lib/actions';
import { getFocusElement } from 'jsfcore/store/helpers/stateToProps';
import { closeHrefModal } from 'jsfcore/store/modules/viewport';
import { setForceFocusElement } from 'jsfcore/store/modules/events';
import { cancellableOpts } from 'jsfcore/store/modules/undoRedo';
import { isVideoUrl } from 'jsfcore/components/Element/ElementHref/ElementHref';
import { nullComponent } from 'jsfcore/helpers/utilsForReact15';
import { showSimpleVideo } from 'combine-modals/lib/actions/actionCreator';

import HrefModalView from './HrefModalView';

@connect(
  (state) => {
    return {
      isHrefModalShown: state.viewport.isHrefModalShown,
      activeElement: getFocusElement(state),
    };
  }, {
    closeHrefModal,
    updateElement,
    setForceFocusElement,
    showSimpleVideo,
  },
)
export default class HrefModal extends Component {
  static propTypes = {
    activeElement: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        content: PropTypes.shape({
          hyperlink: PropTypes.string,
        }),
      }).isRequired,
      PropTypes.bool.isRequired,
    ]).isRequired,
    isHrefModalShown: PropTypes.bool.isRequired,
    closeHrefModal: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    setForceFocusElement: PropTypes.func.isRequired,
    showSimpleVideo: PropTypes.func.isRequired,
  };

  getHref = () => {
    const { activeElement } = this.props;
    return (
      (activeElement.content && activeElement.content.hyperlink)
        ? activeElement.content.hyperlink
        : false
    );
  };

  onClickRemove = () => {
    const { id } = this.props.activeElement;
    this.props.updateElement(id, { hyperlink: null }, cancellableOpts);
    this.props.closeHrefModal();
    this.props.setForceFocusElement(id);
  };

  onClickTest = (hyperlink) => {
    if (isVideoUrl(hyperlink)) {
      this.props.showSimpleVideo({
        params: {
          autostart: true,
          minimizable: false,
          videoId: hyperlink,
        },
        onHide: () => {},
      });
    } else {
      window.open(hyperlink, '_blank');
    }
  };

  onClickSave = (hyperlink) => {
    const { id } = this.props.activeElement;
    this.props.updateElement(id, { hyperlink }, cancellableOpts);
    this.props.closeHrefModal();
    this.props.setForceFocusElement(id);
  };

  onClickClose = () => {
    this.props.closeHrefModal();
    this.props.setForceFocusElement(this.props.activeElement.id);
  };

  render() {
    if (!this.props.isHrefModalShown) {
      return nullComponent();
    }

    return (
      <HrefModalView
        hyperlink={this.getHref()}
        onClickClose={this.onClickClose}
        onClickRemove={this.onClickRemove}
        onClickTest={this.onClickTest}
        onClickSave={this.onClickSave}
      />
    );
  }
}
