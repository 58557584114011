import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const inputFieldSetBehaviors = {
  overflowText: 'overflow-text',
  null: null,
};

export default class InputFieldSetBody extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isInvalid: PropTypes.bool,
    behavior: PropTypes.string,
    div: PropTypes.bool,
  };

  static defaultProps = {
    isInvalid: false,
    behavior: null,
    div: false,
  };

  render() {
    const { isInvalid, behavior, div } = this.props;
    const Wrapper = div
      ? 'div'
      : 'fieldset';
    return (
      <Wrapper
        className={cx(
          'input-fieldset', {
            'is-invalid': isInvalid,
            [`input-fieldset--behavior--${behavior}`]: behavior,
          },
        )}
      >
        <div className="input-fieldset__body">
          {this.props.children}
        </div>
      </Wrapper>
    );
  }
}
