import Body, { inputFieldSetBehaviors as behaviors } from './InputFieldSetBody';
import AdditionalInfo from './InputFieldSetAdditionalInfo';
import Header from './InputFieldSetHeader';
import Main from './InputFieldSetMain';

export default {
  Body,
  AdditionalInfo,
  Header,
  Main,

  behaviors,
};
