import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import * as Ui from 'jsfcore/ui';
import { selectors } from 'jsfcore';
import {
  FocusControllerDisableBlur,
  FocusControllerDisableFocus,
} from '@pdffiller/jsf-focuscontroller';
import { isModalItemsToRender as getIsModalItemsToRender } from 'jsfcore/store/helpers/stateToProps';

import Header from 'jsfcore/components/Header/Header';
import Toolbar from 'jsfcore/components/Toolbar/Toolbar';
import * as Thumbnails from 'jsfcore/components/Thumbnails';
import ThumbnailsSmall from 'jsfcore/components/Thumbnails/ThumbnailsSmall/ThumbnailsSmall';
import Workspace from 'jsfcore/components/Workspace/Workspace';
import PdfDocumentController from 'jsfcore/components/Pdf/PdfDocumentController/PdfDocumentController';
import PdfGetController from 'jsfcore/components/Pdf/PdfGetController/PdfGetController';
import PdfRunner from 'jsfcore/components/Pdf/PdfRunner/PdfRunner';
import PagePrint from 'jsfcore/components/Page/PagePrint/PagePrint';
import * as RightPanel from 'jsfcore/components/RightPanel';
import WorkspaceContinious from 'jsfcore/components/Workspace/WorkspaceContinious';
import * as Ghost from 'jsfcore/components/Ghost';
import ScrollContextProvider from 'jsfcore/components/ScrollContextProvider';
import PopupWrapper from 'jsfcore/components/Toolbar/ToolbarAttributes/Popup/PopupWrapper';
import * as Portal from 'jsfcore/components/Portal';

import JSFModals from 'jsfcore/jsf-modals/components/Modals';
import JSFModalsBackdrop from 'jsfcore/jsf-modals/components/ModalsBackdrop';
import { wizard, LazyComponent } from '@pdffiller/jsf-lazyload';

import { popupStatuses, IFRAME, marginOfLeftSideBar } from 'jsfcore/helpers/const';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { selectors as conditionsSelectors } from '@pdffiller/jsf-conditions';
import {
  scuHelper,
  isContiniousPagination,
  isSimplePagination,
} from 'jsfcore/helpers/utils';
import WorkspaceSize from 'jsfcore/components/Workspace/WorkspaceSize';
import {
  ConnectionLost,
  TryNow,
  ErrorHandler,
  ErrorBoundary,
  DevBuildLabel,
  SmallScreenMessage,
  InterfaceLocker,
  HrefModal,
} from '.';
import { Modals } from '../Modals';
import Scenarios from '../Scenarios/Scenarios';
import ScenariosComponentsMapProvider from '../Scenarios/ScenariosComponentsMapProvider';
import Title from './Title';
import SupportChatButton from './SupportChatButton/SupportChatButton';

const FocusControllerDisableFocusConnected = connect((state) => {
  return {
    disabled: conditionsSelectors.getChoiseByClickingModeIsActive(state),
  };
})(
  FocusControllerDisableFocus,
);

const FocusControllerDisableBlurConnected = connect((state) => {
  return {
    disabled: (
      selectors.getIsModalVisible(state) ||
      state.viewport.popupVisibility === popupStatuses.readyToShow ||
      state.viewport.popupVisibility === popupStatuses.shown ||
      getIsModalItemsToRender(state) ||
      conditionsSelectors.getChoiseByClickingModeIsActive(state)
    ),
  };
})(
  FocusControllerDisableBlur,
);

const attributes = {
  ...Ui.attributes.grow,
  ...Ui.attributes.shrink,
};

const JsfMain = ({ children, havePdfDocument }) => {
  return (
    <div
      className={cx('jsf-main', {
        'jsf-main--loading': !havePdfDocument,
      })}
    >
      {children}
    </div>
  );
};

JsfMain.propTypes = {
  children: PropTypes.node.isRequired,
  havePdfDocument: PropTypes.bool.isRequired,
};

const JsfWrapDocument = ({ children, storeRef }) => {
  return (
    <div
      className="jsf-wrap-document"
      ref={storeRef}
    >
      {children}
    </div>
  );
};

JsfWrapDocument.propTypes = {
  children: PropTypes.node.isRequired,
  storeRef: PropTypes.func.isRequired,
};

class ApplicationView extends Component {
  static propTypes = {
    appStarted: PropTypes.bool.isRequired,
    showLocker: PropTypes.bool.isRequired,
    flexNodeReduceHeight: PropTypes.number.isRequired,
    havePdfDocument: PropTypes.bool.isRequired,
    scenarios: PropTypes.shape({}),
    previewUrl: PropTypes.string,
    projectName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    appStartTimeout: PropTypes.shape({
      deactivateAppTimeout: PropTypes.func.isRequired,
      restartAppTimeout: PropTypes.func.isRequired,
    }).isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
    popupVisibility: PropTypes.oneOf(
      Object.values(popupStatuses),
    ).isRequired,
    isMobileWizardTodoListActive: PropTypes.bool.isRequired,
    isShownPhoneButtonSheetMenu: PropTypes.bool.isRequired,
    isBusyLockerShown: PropTypes.bool.isRequired,
    isChoiceIframeShown: PropTypes.bool.isRequired,
    doneButton: PropTypes.shape({
      main: PropTypes.shape({
        location: PropTypes.string.isRequired,
        target: PropTypes.string,
      }).isRequired,
    }),
    isVersionsMode: PropTypes.bool.isRequired,
    isFeedbackLoaded: PropTypes.bool.isRequired,
    isThumbnailsOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    scenarios: null,
    previewUrl: null,
    doneButton: null,
  };

  static childContextTypes = {
    getWorkspaceRef: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { isThumbnailsMounted: false };
  }

  getChildContext() {
    return {
      getWorkspaceRef: this.getWorkspaceRef,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return scuHelper(nextProps, nextState, this, [
      'havePdfDocument', 'flexNodeReduceHeight', 'projectName',
      'scenarios', 'showLocker',
      'appStarted', 'isWizardLoaded', 'isMobileWizardTodoListActive',
      'isShownPhoneButtonSheetMenu',
      'appStarted', 'isWizardLoaded',
      'isBusyLockerShown', 'isChoiceIframeShown', 'doneButton',
      'isVersionsMode', 'isFeedbackLoaded', 'isThumbnailsOpen',
    ]);
  }

  getWorkspaceRef = () => {
    return this.workspace;
  };

  storeWorkspaceRef = (ref) => {
    this.workspace = ref;
  };

  isPopUpVisible = () => {
    return this.props.popupVisibility === popupStatuses.shown;
  };

  onThumbnailsMount = () => {
    setTimeout(() => {
      this.setState({
        isThumbnailsMounted: true,
      });
    }, 300);
  };

  renderPreview = () => {
    const { previewUrl } = this.props;
    return (
      <div className="g-scrollbar ps-container">
        {previewUrl &&
          <img
            alt="preview"
            src={previewUrl}
            className="pdf-preview-image"
          />
        }
      </div>
    );
  };

  renderChoiceIframe = () => {
    const {
      doneButton,
      isChoiceIframeShown,
    } = this.props;

    const iframeWrapperClass = `iframe-overlay ${
      isChoiceIframeShown
        ? ''
        : 'is-hidden'
    }`;

    return (
      <div className={iframeWrapperClass}>
        <iframe
          src={doneButton.main.location}
          title="choice"
          className="iframe-overlay__content"
          frameBorder="0"
          allow="encrypted-media"
          allowFullScreen
        />
      </div>
    );
  };

  render() {
    const { isThumbnailsMounted } = this.state;
    const {
      havePdfDocument,
      flexNodeReduceHeight,
      projectName,
      scenarios,
      showLocker,
      isWizardLoaded,
      isShownPhoneButtonSheetMenu,
      isBusyLockerShown,
      appStarted,
      isMobileWizardTodoListActive,
      appStartTimeout,
      doneButton,
      isVersionsMode,
      isFeedbackLoaded,
      isThumbnailsOpen,
    } = this.props;

    // If we pass string of length=0 to Helmet - we won't have title at all (even if template is ok)
    const helmetTitle = projectName === false
      ? ' '
      : ` - ${projectName}`;
    const shouldRenderWizardController =
      havePdfDocument && isThumbnailsMounted && isWizardLoaded && !isVersionsMode;

    return (
      <Fragment>
        <ScrollContextProvider>
          <Portal.Layer.RefProvider>
            {({ storeRef }) => {
              return (
                <Fragment>
                  <ErrorBoundary>
                    <Fragment>
                      <Portal.Layer.Component storeRef={storeRef} />
                      {appStarted &&
                        <PagePrint />
                      }
                      <div
                        className="grid"
                        style={{
                          height: `calc(100% - ${flexNodeReduceHeight}px)`,
                        }}
                      >
                        <Header isThumbnailsMounted={isThumbnailsMounted} />
                        <Title title={helmetTitle} />
                        <PdfDocumentController />
                        {!thisDevice.isPhone && appStarted &&
                          <Toolbar />
                        }
                        {shouldRenderWizardController &&
                          <LazyComponent
                            literal={wizard.components.wizardController}
                          />
                        }
                        <Ui.Grid.CellGroup
                          attributes={attributes}
                          size={
                            // changes requested by Nikola Markelov
                            // fixes right-sidebar collapsing behavior in Firefox
                            // https://pdffiller.atlassian.net/browse/JSF-4424
                            thisDevice.isFirefoxDesktop
                              ? Ui.grid.cellGroup.sizes.medium
                              : null
                          }
                        >
                          <Ui.Grid.Cell
                            style={!isThumbnailsOpen
                              ? marginOfLeftSideBar
                              : null}
                          >
                            <Ui.SideBar.Body
                              theme={Ui.sideBar.themes.lightgray}
                              size={Ui.sideBar.sizes.medium}
                              dataPagination={!isThumbnailsOpen}
                            >
                              {!thisDevice.isPhone && (
                                <ThumbnailsSmall />
                              )}
                              <Ui.SideBar.Content>
                                <Thumbnails.renderThumbnails
                                  onThumbnailsMount={this.onThumbnailsMount}
                                />
                              </Ui.SideBar.Content>
                            </Ui.SideBar.Body>
                          </Ui.Grid.Cell>
                          <Ui.Grid.Separator />
                          <Ui.Grid.Cell attributes={Ui.attributes.grow}>
                            <JsfMain havePdfDocument={havePdfDocument}>
                              {havePdfDocument && <PdfGetController />}
                              {havePdfDocument && <PdfRunner />}
                              <JsfWrapDocument storeRef={this.storeWorkspaceRef}>
                                {!havePdfDocument &&
                                  this.renderPreview()
                                }
                                {havePdfDocument && isSimplePagination() &&
                                  <Workspace />
                                }
                                {havePdfDocument && isContiniousPagination() &&
                                  <WorkspaceContinious />
                                }
                              </JsfWrapDocument>
                            </JsfMain>
                          </Ui.Grid.Cell>
                          {!thisDevice.isPhone && (
                            <Ui.Grid.Separator />
                          )}
                          {!thisDevice.isPhone && (
                            <Ui.Grid.Cell
                              type={Ui.grid.types.topLayer}
                            >
                              <RightPanel.Renderer />
                            </Ui.Grid.Cell>
                          )}
                        </Ui.Grid.CellGroup>
                        <SmallScreenMessage />
                        {havePdfDocument && thisDevice.isMobile &&
                          <LazyComponent
                            literal={wizard.components.wizardFooter}
                          />
                        }
                        <DevBuildLabel />
                        <ConnectionLost />
                        <TryNow appStartTimeout={appStartTimeout} />
                        <HrefModal />
                        {(showLocker || isBusyLockerShown) && (
                          <InterfaceLocker
                            busyCursor={isBusyLockerShown}
                          />
                        )}
                        {isShownPhoneButtonSheetMenu && thisDevice.isPhone &&
                          <LazyComponent
                            literal={wizard.components.wizardPhoneBottomSheetMenu}
                          />
                        }
                        {scenarios &&
                          <ScenariosComponentsMapProvider>
                            <Scenarios scenarios={scenarios} />
                          </ScenariosComponentsMapProvider>
                        }
                        {appStarted && !isVersionsMode && (
                          thisDevice.isMobile
                            ? <Ghost.FreeGhostMobile />
                            : <Ghost.FreeGhost />
                        )}
                      </div>
                      {thisDevice.isMobile && this.isPopUpVisible() && !thisDevice.isTablet &&
                        <Ui.Grid.CellGroup>
                          <Ui.Grid.Cell attributes={Ui.attributes.grow}>
                            <PopupWrapper />
                          </Ui.Grid.Cell>
                        </Ui.Grid.CellGroup>
                      }
                      {thisDevice.isPhone && isMobileWizardTodoListActive &&
                        <LazyComponent
                          literal={wizard.components.wizardTodoListMobile}
                        />
                      }
                      <JSFModalsBackdrop />
                      <JSFModals />
                      <WorkspaceSize getRef={this.getWorkspaceRef} />
                      {doneButton && doneButton.main.target === IFRAME && appStarted &&
                        this.renderChoiceIframe()
                      }
                      <FocusControllerDisableBlurConnected />
                      <FocusControllerDisableFocusConnected />
                    </Fragment>
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <ErrorHandler />
                  </ErrorBoundary>
                  <SupportChatButton />
                  <Modals isFeedbackLoaded={isFeedbackLoaded} />
                </Fragment>
              );
            }}
          </Portal.Layer.RefProvider>
        </ScrollContextProvider>
      </Fragment>
    );
  }
}

export default ApplicationView;
