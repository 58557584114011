import React, { Component } from 'react';

const Loader = () => {
  return null;
};

const createModalLazyComponent = ({
  getLoadPromise,
  modalId,
}) => {
  return class ModalLazyComponent extends Component {
    static getModalId() {
      return modalId;
    }

    constructor(props) {
      super(props);
      this.modalId = modalId;
      this.Component = Loader;
    }

    componentDidMount() {
      this.load();
    }

    load = async () => {
      this.Component = (await getLoadPromise()).default;
      this.setState({});
    };

    render() {
      const { Component: Modal } = this;
      return (
        <Modal {...this.props} />
      );
    }
  };
};

export default createModalLazyComponent;
