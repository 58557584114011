/* eslint-disable prefer-destructuring */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { systemStatuses, webPageId, supportPageUrl } from 'jsfcore/helpers/const';
import getLoaderApi from '@pdffiller/jsf-loader/api';
import { setFatalError } from 'jsfcore/store/modules/events';
import { selectors, thunks } from 'jsfcore';
import { destroy, trackPoint, serverError } from 'ws-editor-lib/actions';

import logger, { SessionContext, ErrorContext } from '@pdffiller/jsf-logger/clientLogger';
import { FEEDBACK_MODAL_OOPS_MODE } from 'combine-modals/lib/constants/default.constants';
import Error from './Error';

const appender = logger.popupAppender;

const WS_ERROR = 'WSError';

@connect(
  (state) => {
    return {
      wsErrors: selectors.base.getErrorList(state),
      system: selectors.base.getSystem(state),
      fatalError: selectors.base.getFatalError(state),
    };
  }, {
    destroy,
    trackPoint,
    setFatalError,
    serverError,
    openFeedbackModal: thunks.openFeedbackModal,
  },
)
export default class ErrorHandler extends Component {
  static propTypes = {
    wsErrors: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.shape({
          message: PropTypes.string.isRequired,
        }).isRequired,
      ]),
    ).isRequired,
    system: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
    fatalError: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]),

    destroy: PropTypes.func.isRequired,
    trackPoint: PropTypes.func.isRequired,
    serverError: PropTypes.func.isRequired,
    setFatalError: PropTypes.func.isRequired,
    openFeedbackModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fatalError: false,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.wslog = logger.getLogger('ws');
    this.fatalProcessed = false;

    if (__CLIENT__) {
      window.webPageId = webPageId;
    }
  }

  componentDidMount() {
    appender && appender.setHandler((name, level, props) => {
      let error = null;
      let errorContext = null;
      if ((level === 'error' || level === 'fatal') && props.length > 2) {
        if (!error && props[2] instanceof ErrorContext && props[2].error) {
          errorContext = props[2];
          error = props[2].error;
        }

        if (!error && props[2] instanceof SessionContext) {
          errorContext = props[2];
          error = props[2];
        }
      }

      if (error && error.type === WS_ERROR) {
        for (let idx = 0; idx < error.data.length; idx++) {
          if (error.data[idx]) {
            const wsError = error.data[idx];
            const messages = [];
            if (wsError.error) {
              for (let idx2 = 0; idx2 < wsError.error.length; idx2++) {
                const error2 = wsError.error[idx2];
                messages.push(`${error2.code}:${error2.msg}`);
                if (error2.errors) {
                  messages.push(error2.errors.map((item) => {
                    return item.stack;
                  }));
                }

                if (wsError.operation) {
                  const errMess = [
                    wsError.operation.id
                      ? wsError.operation.id.clientId
                      : '-',
                    wsError.operation.id
                      ? wsError.operation.id.localId
                      : '-',
                    wsError.operation.properties
                      ? wsError.operation.properties.group
                      : '-',
                    wsError.operation.properties
                      ? wsError.operation.properties.type
                      : '-',
                    wsError.operation.properties
                      ? wsError.operation.properties.subType
                      : '-',
                  ].join(':');

                  messages.push(`operation(${errMess})`);
                }
              }
            } else if (wsError.message) {
              messages.push(wsError.message);
            } else {
              messages.push(wsError);
            }

            this.setState((prevState) => {
              return { errors: [...prevState.errors, messages.join('<br/>')] };
            });
          }
        }
      } else {
        const messages = [];
        messages.push(name);
        messages.push(props[1]);
        if (error && error.message) {
          messages.push(error.message);
        }

        this.setState((prevState) => {
          return { errors: [...prevState.errors, messages.join('<br/>')] };
        });
      }

      if (level === 'fatal') {
        this.props.setFatalError({
          type: errorContext && errorContext.type,
          message: error && error.message,
          frames: errorContext && errorContext.frames,
        });
      }
    });

    if (__CLIENT__) {
      window.kyivError = () => {
        this.openFeedBackModal();
        this.props.trackPoint('SCRIPT_EXCEPTION', {
          type: 'kyivError',
          message: 'kyivError',
          frames: [{
            fileName: 'kyivError',
            lineNumber: 1408,
            columnNumber: 322,
            source: 'kyivError',
          }],
        });
      };
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ wsErrors, fatalError, system }) {
    // clear errors when opens a new project in embedded mode
    if (
      (!wsErrors.length && this.props.wsErrors.length) ||
      (!fatalError && this.props.fatalError)
    ) {
      this.setState({ errors: [] });
    }

    if (wsErrors.length !== this.props.wsErrors.length) {
      const errors = wsErrors.slice(this.props.wsErrors.length, wsErrors.length);
      this.wslog.error('WebSocket errors', new SessionContext({ type: WS_ERROR, data: errors }));
    }

    if (!this.fatalProcessed && fatalError && !this.props.fatalError &&
      system.status !== systemStatuses.destroy &&
      system.status !== systemStatuses.destroyed) {
      this.fatalProcessed = true;
      if (fatalError.type !== WS_ERROR) {
        this.props.trackPoint('SCRIPT_EXCEPTION', {
          type: fatalError.type,
          message: fatalError.message,
          frames: fatalError.frames,
        });
      }
      this.openFeedBackModal();
      getLoaderApi().hide();
      // destroy logger and connection
      setTimeout(() => {
        logger.loggerDestroy();
        this.props.serverError();
      }, 1000);
    }
  }

  reload = () => {
    try {
      this.props.destroy(window.location.href);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  openFeedBackModal = () => {
    const options = {
      mode: FEEDBACK_MODAL_OOPS_MODE,
      showReloadButton: true,
      isFeedbackMessageRequired: true,
      showRequiredMessage: true,
      reloadCallBack: this.reload,
      sendOopsCallBack: (message) => {
        if (message.length === 0) {
          window.open(supportPageUrl);
        }
        this.reload();
      },
      afterSendOopsCallBack: () => {
        window.open(supportPageUrl);
      },
      handleCloseModalCallback: this.reload,
    };

    this.props.openFeedbackModal(options);
  };

  removeError = (index) => {
    return () => {
      const { errors } = this.state;
      const newErrors = [
        ...errors.slice(0, index),
        ...errors.slice(index + 1),
      ];
      this.setState({ errors: newErrors });
    };
  };

  render() {
    return (
      <div className="notifications-wrapper">
        <div className="notifications-tr">
          {appender && appender.develop && this.state.errors.map((item, index) => {
            return (
              <Error message={item} key={item} onClick={this.removeError(index)} />
            );
          })}
        </div>
      </div>
    );
  }
}
