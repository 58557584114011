import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { hideVideoHelpPreview } from 'combine-modals/lib/actions/actionCreator';

// Video Help (Preview hide)
// "action": "video_help.preview.hide",  идентификатор действия: скрыть диалог привью видео
// "apply": true,  применять ли данное действие
// "runOnce": true,  запустить единоразово
// "delay": 5000  задержка перед выполнением, (ms)
// вызов диалога: store.dispatch(actionCreator.hideVideoHelpPreview())

@connect(
  null, {
    hideVideoHelpPreview,
  },
)
export default class VideoHelpPreviewHide extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['video_help.preview.hide']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,

    hideVideoHelpPreview: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.hideVideoHelpPreview();
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    return null;
  }
}
