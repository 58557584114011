import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
  destroy, trackPoint,
} from 'ws-editor-lib/actions';
import { thunks, selectors } from 'jsfcore';
import {
  openModal,
  closeModal,
} from 'combine-modals/lib/actions/actionCreator';
import {
  updateWorkspace,
  recomputeWorkspacePadding,
  setPopupShown,
} from 'jsfcore/store/modules/viewport';

import config from 'jsfcore/helpers/clientConfig';
import { getOrientation, isInIframe } from 'jsfcore/helpers/utils';
import {
  systemStatuses,
  popupStatuses,
} from 'jsfcore/helpers/const';

import { dispatchAction } from '@pdffiller/jsf-lazyload';
import { jsf as jsfLazySelectors } from '@pdffiller/jsf-lazyload/store/selectors';
import logger from '@pdffiller/jsf-logger/clientLogger';
import KeyboardController from 'modules/keyboardController';
import { loadFont } from '@pdffiller/jsf-fontloader';
import { thisDevice } from '@pdffiller/jsf-useragent';
import uiAssets from 'jsfiller.ui/assets.json';

import ApplicationView from './ApplicationView';
import AppStartTimeout from './AppStartTimeout';

@connect(
  (state) => {
    return {
      havePdfDocument: state.pdf.hasPdfDocument,
      body: state.viewport.body,
      keyboardSize: selectors.base.getKeyboardSize(state),
      popupSize: state.viewport.popupSize,
      isThumbnailsOpen: selectors.base.getIsThumbnailsOpen(state),
      popupVisibility: state.viewport.popupVisibility,
      wizardFooterHeight: state.viewport.wizardFooterHeight,
      processingModal: selectors.locale.getProcessingModalLocale(state),
      modeId: state.ws.modeId,
      previewUrl: state.query.previewUrl,
      scenarios: selectors.base.getWsScenarios(state),
      systemStatus: selectors.base.getSystemStatus(state),
      appStarted: selectors.base.getAppStarted(state),
      projectName: state.ws.projectName,
      doneButton: selectors.base.getDoneButton(state),
      defaultTool: selectors.getDefaultTool(state),
      framePadding: state.viewport.workspace.framePadding,
      isPAConstructorShown: state.viewport.isPAConstructorShown,
      isFConstructorShown: state.viewport.isFConstructorShown,
      isChoiceIframeShown: selectors.base.getIsChoiceIframeShown(state),
      isCommentsModeActive: selectors.mode.isComments(state),
      isPointerModeActive: selectors.mode.isPointer(state),
      isWizardLoaded: state.lazyLoad.wizard,
      isMobileWizardTodoListActive: get(state, 'wizard.isMobileWizardTodoListActive', false),
      isShownPhoneButtonSheetMenu: get(state, 'wizard.isShownPhoneButtonSheetMenu', false),
      isBusyLockerShown: selectors.base.getIsBusyLockerShown(state),
      isVersionsMode: selectors.mode.isVersions(state),
      isFeedbackLoaded: jsfLazySelectors.getIsFeedbackLoaded(state),
    };
  }, {
    updateWorkspace,
    recomputeWorkspacePadding,
    setPopupShown,
    removeElement: thunks.remove,
    openModal,
    destroy,
    trackPoint,
    closeModal,
    setActiveElement: thunks.setActiveElement,
  },
)
export default class Application extends Component {
  static propTypes = {
    doneButton: PropTypes.shape({
      main: PropTypes.shape({
        location: PropTypes.string.isRequired,
      }).isRequired,
    }),
    scenarios: PropTypes.shape({}),
    body: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    havePdfDocument: PropTypes.bool.isRequired,
    keyboardSize: PropTypes.shape({
      landscape: PropTypes.number.isRequired,
      portrait: PropTypes.number.isRequired,
    }).isRequired,

    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    popupSize: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]).isRequired,
    popupVisibility: PropTypes.oneOf(
      Object.values(popupStatuses),
    ).isRequired,
    wizardFooterHeight: PropTypes.number.isRequired,
    processingModal: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    systemStatus: PropTypes.string.isRequired,
    modeId: PropTypes.string,
    previewUrl: PropTypes.string,
    appStarted: PropTypes.bool.isRequired,
    projectName: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    defaultTool: PropTypes.shape({
      type: PropTypes.string.isRequired,
      subType: PropTypes.string.isRequired,
    }).isRequired,
    framePadding: PropTypes.shape({}).isRequired,
    isMobileWizardTodoListActive: PropTypes.bool.isRequired,
    isShownPhoneButtonSheetMenu: PropTypes.bool.isRequired,
    isBusyLockerShown: PropTypes.bool.isRequired,

    // actions
    /* eslint-disable react/no-unused-prop-types */
    updateWorkspace: PropTypes.func.isRequired,
    recomputeWorkspacePadding: PropTypes.func.isRequired,
    setPopupShown: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    trackPoint: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    setActiveElement: PropTypes.func.isRequired,
    removeElement: PropTypes.func.isRequired,

    isThumbnailsOpen: PropTypes.bool.isRequired,
    isPAConstructorShown: PropTypes.bool.isRequired,
    isFConstructorShown: PropTypes.bool.isRequired,
    isCommentsModeActive: PropTypes.bool.isRequired,
    isPointerModeActive: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
    isChoiceIframeShown: PropTypes.bool.isRequired,
    isVersionsMode: PropTypes.bool.isRequired,
    isFeedbackLoaded: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    doneButton: null,
    body: null,
    modeId: null,
    previewUrl: null,
    scenarios: null,
    projectName: false,
  };

  static contextTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  static childContextTypes = {
    getViewport: PropTypes.func,
    getEvents: PropTypes.func,
    getActiveElement: PropTypes.func,
    getDefaultTool: PropTypes.func,
  };

  constructor(props) {
    super(props);

    props.recomputeWorkspacePadding();
    if (__CLIENT__) {
      uiAssets.fonts.forEach(loadFont);
    }
    this.state = { flexNodeReduceHeight: 0 };
  }

  getChildContext = () => {
    return {
      getViewport: this.getViewport,
      getActiveElement: this.getActiveElement,
      getEvents: this.getEvents,
      getDefaultTool: this.getDefaultTool,
    };
  };

  componentDidMount() {
    if (!this.props.appStarted) {
      logger.loggerInit();
      // AB: делаем инициализацию статистики с задержкой,
      // чтобы не мешала основной загрузке, 1с взята от балды
      setTimeout(this.gaInit, 1000);
    }

    this.keyboardController = new KeyboardController(this.context);
    this.keyboardSize = false;

    // когда пользователь кликает вне фрейма с эдитором (airSlate), то деактивируем элемент
    // сделано для того чтобы текстовый тул не забирал фокус у модалок из airSlate (JSF-5882)
    try {
      if (__CLIENT__ && window.document !== window.parent.document) {
        window.parent.document.addEventListener('click', this.onOutsideFrameClick);
      }
    } catch (error) {
      // avoid cross-origin error
      // eslint-disable-next-line no-console
      console.log(error);
    }

    if (thisDevice.isIOS) {
      // disable zoom on iPhones
      const preventDefault = (event) => {
        event.preventDefault();
      };

      document.addEventListener('gesturestart', preventDefault);
      document.addEventListener('gesturechange', preventDefault);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      body,
      keyboardSize,
      popupSize,
      popupVisibility,
    } = nextProps;

    const {
      keyboardSize: oldKeyboardSize,
      body: oldBody,
      popupVisibility: oldPopupVisibility,
    } = this.props;

    const popupBecameHidden =
      oldPopupVisibility !== popupStatuses.hidden &&
      popupVisibility === popupStatuses.hidden;

    if (
      !isInIframe() && (
        keyboardSize !== oldKeyboardSize ||
        body !== oldBody ||
        popupBecameHidden
      )
    ) {
      this.onKeyboardChange(keyboardSize, popupSize, body);
    }

    if (
      oldPopupVisibility !== popupStatuses.readyToShow &&
      popupVisibility === popupStatuses.readyToShow
    ) {
      this.props.setPopupShown();
    }

    if (
      !isInIframe() && (
        oldPopupVisibility !== popupStatuses.shown &&
        popupVisibility === popupStatuses.shown &&
        !thisDevice.isTablet
      )
    ) {
      const orientation = getOrientation(body);
      this.setState({
        flexNodeReduceHeight: popupSize[orientation],
      });
    }

    // TODO: move to load/shown thunk\saga
    if (
      (this.props.isPointerModeActive !== nextProps.isPointerModeActive) ||
      (this.props.isCommentsModeActive !== nextProps.isCommentsModeActive)
    ) {
      this.props.recomputeWorkspacePadding();
    }
  }

  componentWillUnmount() {
    this.keyboardController.destroy();

    try {
      if (__CLIENT__ && window.document !== window.parent.document) {
        window.parent.document.removeEventListener('click', this.onOutsideFrameClick);
      }
    } catch (error) {
      // avoid cross-origin error
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  getViewport = () => {
    const state = this.context.store.getState();
    return state.viewport;
  };

  getEvents = () => {
    const state = this.context.store.getState();
    return state.events;
  };

  getActiveElement = () => {
    const state = this.context.store.getState();
    return selectors.elements.getActiveElement(state);
  };

  // появится ошибка, если заменить на:
  //  ```const state = this.context.store.getState();
  //  return getDefaultTool(state);```
  getDefaultTool = () => {
    return this.props.defaultTool;
  };

  gaInit = () => {
    if (__CLIENT__) {
      // const host = document.location.hostname.split('.');
      TagManager.initialize({
        gtmId: config.ga.gtmId,
      });
    }
  };

  onKeyboardChange = (keyboardSize, popupSize, body) => {
    const orientation = getOrientation(body);

    if (!popupSize || popupSize[orientation] === 0) {
      this.setState({
        flexNodeReduceHeight: keyboardSize[orientation],
      });
    }

    if (keyboardSize[orientation] === 0 && this.keyboardSize === true) {
      this.keyboardSize = false;
      window.removeEventListener('scroll', this.onScrollWithKeyboard);
    }

    if (keyboardSize[orientation] !== 0 && this.keyboardSize === false) {
      this.keyboardSize = true;
      window.addEventListener('scroll', this.onScrollWithKeyboard);
    }
  };

  onScrollWithKeyboard = () => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  };

  onOutsideFrameClick = (e) => {
    if (e.target) {
      const activeElement = this.getActiveElement();
      if (activeElement) {
        dispatchAction(this.props.setActiveElement(activeElement.id, false));
      }
    }
  };

  render() {
    return (
      <AppStartTimeout>
        {({ appStartTimeout }) => {
          return (
            <ApplicationView
              key="appView"
              appStarted={this.props.appStarted}
              flexNodeReduceHeight={
                // TODO: Can we summarize here 3 heights?
                // now: (keyboard || settings popup) + wizard (can 0)
                this.state.flexNodeReduceHeight + this.props.wizardFooterHeight
              }
              havePdfDocument={this.props.havePdfDocument}
              scenarios={this.props.scenarios}
              modeId={this.props.modeId}
              previewUrl={this.props.previewUrl}
              projectName={this.props.projectName}
              showLocker={
                this.props.systemStatus === systemStatuses.destroy ||
                this.props.systemStatus === systemStatuses.destroyed
              }
              isThumbnailsOpen={this.props.isThumbnailsOpen}
              popupVisibility={this.props.popupVisibility}
              appStartTimeout={appStartTimeout}
              framePadding={this.props.framePadding}

              // functions
              isPAConstructorShown={this.props.isPAConstructorShown}
              isWizardLoaded={this.props.isWizardLoaded}
              isMobileWizardTodoListActive={this.props.isMobileWizardTodoListActive}
              isShownPhoneButtonSheetMenu={this.props.isShownPhoneButtonSheetMenu}
              isBusyLockerShown={this.props.isBusyLockerShown}
              isChoiceIframeShown={this.props.isChoiceIframeShown}
              doneButton={this.props.doneButton}
              isVersionsMode={this.props.isVersionsMode}
              isFeedbackLoaded={this.props.isFeedbackLoaded}
            />
          );
        }}
      </AppStartTimeout>
    );
  }
}
