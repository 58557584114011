import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import fieldNamesMap, { defaultDialogSize, defaultDialogOffset, radioAlignmentModes } from './const';
import validateGroupName from '../../../helpers/validateDatabaseName';

import {
  actions as jsfcoreModalsActions,
  types as jsfcoreModalsTypes,
} from '../..';
import Radio from './Radio';
import RadioFooter from './RadioFooter';
import RadioHeader from './RadioHeader';

const RADIO_VALUES_MINIMUM_LINE_COUNT = 2;

@connect(
  null, {
    closeModal: jsfcoreModalsActions.closeModal,
  },
)
export default class RadioProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      required: true,
      groupName: '',
      // eslint-disable-next-line
      groupValues: '',
      parsedGroupValues: [],
      isVerticalAlignment: true,
    };
  }

  getMappedValues = () => {
    const { required, groupName, parsedGroupValues, isVerticalAlignment } = this.state;
    const radioAlignmentMode = isVerticalAlignment
      ? radioAlignmentModes.vertical
      : radioAlignmentModes.horizontal;

    return {
      [fieldNamesMap.alignment]: radioAlignmentMode,
      [fieldNamesMap.required]: required,
      [fieldNamesMap.groupName]: groupName,
      [fieldNamesMap.groupValues]: parsedGroupValues,
    };
  };

  getIsSelectButtonDisabled = () => {
    if (this.state.groupName === '') {
      return true;
    }

    if (this.state.parsedGroupValues.length < RADIO_VALUES_MINIMUM_LINE_COUNT) {
      return true;
    }

    return false;
  };

  onChange = (name, value) => {
    const newState = {
      [name]: value,
    };

    if (name === 'groupName') {
      newState.groupName = validateGroupName(value);
    }

    // get parsed values from textarea splitted by "\n" symbol (line feed)
    if (name === 'groupValues') {
      newState.parsedGroupValues = value.split('\n').filter((groupValue) => {
        return groupValue.trim().length;
      });
    }

    this.setState(newState);
  };

  onClose = () => {
    this.props.closeModal(
      jsfcoreModalsTypes.modalsMap[jsfcoreModalsTypes.modalTypes.radio],
    );
  };

  onSelect = () => {
    this.props.closeModal(
      jsfcoreModalsTypes.modalsMap[jsfcoreModalsTypes.modalTypes.radio],
      this.getMappedValues(),
    );
  };

  onRadioChange = () => {
    this.setState((prevState) => {
      return { isVerticalAlignment: !prevState.isVerticalAlignment };
    });
  };

  render() {
    const isSelectButtonDisabled = this.getIsSelectButtonDisabled();

    return this.props.children({
      onClose: this.onClose,
      header: (
        <RadioHeader
          onClose={this.onClose}
        />
      ),
      footer: (
        <RadioFooter
          onClose={this.onClose}
          onSelect={this.onSelect}
          isSelectButtonDisabled={isSelectButtonDisabled}
        />
      ),
      component: (
        <Radio
          groupName={this.state.groupName}
          groupValuesCount={this.state.parsedGroupValues.length}
          required={this.state.required}
          onChange={this.onChange}
          isVerticalAlignment={this.state.isVerticalAlignment}
          onRadioChange={this.onRadioChange}
        />
      ),
      props: {
        size: defaultDialogSize,
        offset: defaultDialogOffset,
      },
    });
  }
}
