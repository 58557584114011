import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { thunks, selectors } from 'jsfcore';
import { connectionStatuses, systemStatuses } from 'jsfcore/helpers/const';
import getLoaderApi from '@pdffiller/jsf-loader/api';
import {
  showChoiceIframe,
  hideChoiceIframe,
  cancelShowingBeforeUnloadPromptConfirmation,
} from 'jsfcore/store/modules/viewport';
import Portal from 'jsfcore/components/Portal';
import logger from '@pdffiller/jsf-logger/clientLogger';

import ConnectionLostView from './ConnectionLostView';

@connect(
  (state) => {
    const appStarted = selectors.base.getAppStarted(state);
    const isBusyAccessStatus = selectors.getIsBusyAccessStatus(state);
    const isNeedConnectionProcessing = appStarted || isBusyAccessStatus;

    return {
      activeElement: selectors.base.getActiveElementId(state),
      connectionLostLocale: selectors.locale.getEditorLocale(state).connectionLost,
      reconnectLostLocale: selectors.locale.getEditorLocale(state).reconnect,
      connectionStatus: selectors.base.getConnectionStatus(state),
      systemStatus: selectors.base.getSystemStatus(state),
      fatalError: selectors.base.getFatalError(state),
      isChoiceIframeShown: selectors.base.getIsChoiceIframeShown(state),
      isNeedConnectionProcessing,
    };
  }, {
    setActiveElement: thunks.setActiveElement,
    showChoiceIframe,
    hideChoiceIframe,
    cancelShowingBeforeUnloadPromptConfirmation,
  },
)
export default class ConnectionLost extends Component {
  static propTypes = {
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    activeElement: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    connectionStatus: PropTypes.string.isRequired,
    systemStatus: PropTypes.string.isRequired,
    connectionLostLocale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      retry: PropTypes.string.isRequired,
    }).isRequired,
    reconnectLostLocale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      retry: PropTypes.string.isRequired,
    }).isRequired,

    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    isNeedConnectionProcessing: PropTypes.bool.isRequired,
    fatalError: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]).isRequired,
    isChoiceIframeShown: PropTypes.bool.isRequired,
    setActiveElement: PropTypes.func.isRequired,
    showChoiceIframe: PropTypes.func.isRequired,
    hideChoiceIframe: PropTypes.func.isRequired,
    cancelShowingBeforeUnloadPromptConfirmation: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.connectionLost = false;
    this.reconnect = false;
    this.isChoiceIframeWasHidden = false;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    connectionStatus,
    systemStatus,
    activeElement,
    isNeedConnectionProcessing,
  }) {
    if (isNeedConnectionProcessing &&
      connectionStatus === connectionStatuses.disconnect &&
      connectionStatus !== this.props.connectionStatus
    ) {
      if (activeElement !== false) {
        this.props.setActiveElement(activeElement, false);
      }
      logger.logConnectionLost();
      getLoaderApi().hide();
      setTimeout(() => {
        logger.loggerDestroy();
      }, 1000);
      this.hideChoiceIframeIfNeed();
      this.connectionLost = true;
    }

    if ((connectionStatus !== this.props.connectionStatus ||
        systemStatus !== this.props.systemStatus) &&
      isNeedConnectionProcessing &&
      connectionStatus !== connectionStatuses.connect &&
      systemStatus !== systemStatuses.operationsReceive
    ) {
      this.hideChoiceIframeIfNeed();
      this.reconnect = true;
    }

    if ((connectionStatus !== this.props.connectionStatus ||
        systemStatus !== this.props.systemStatus) &&
      isNeedConnectionProcessing &&
      connectionStatus === connectionStatuses.connect &&
      systemStatus === systemStatuses.operationsReceive
    ) {
      logger.logReconnect();
      this.showChoiceIframeIfNeed();
      this.reconnect = false;
    }
  }

  hideChoiceIframeIfNeed = () => {
    if (this.props.isChoiceIframeShown) {
      this.props.hideChoiceIframe();
      this.isChoiceIframeWasHidden = true;
    }
  };

  showChoiceIframeIfNeed = () => {
    if (this.isChoiceIframeWasHidden) {
      this.props.showChoiceIframe();
      this.isChoiceIframeWasHidden = false;
    }
  };

  onClickRetry = () => {
    this.props.cancelShowingBeforeUnloadPromptConfirmation();
    window.location.reload();
  };

  render() {
    if (this.props.fatalError) {
      return null;
    }

    if (!this.reconnect && !this.connectionLost) {
      return null;
    }

    return (
      <Portal>
        <ConnectionLostView
          locale={this.connectionLost
            ? this.props.connectionLostLocale
            : this.props.reconnectLostLocale}
          onClickRetry={this.onClickRetry}
          showRetry={this.connectionLost}
        />
      </Portal>
    );
  }
}
