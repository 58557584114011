import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showDeclineDialog } from 'combine-modals/lib/actions/actionCreator';
import { renderScenario, ifApply } from '../scenariosUtils';

@connect(
  null, {
    showDeclineDialog,
  },
)
export default class DeclineDialogShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['decline.dialog.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      clearDataFlag: ifApply(PropTypes.bool),
      message: ifApply(PropTypes.string),
    }).isRequired,
    onDecline: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    onCancel: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,

    showDeclineDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      decline: false,
      cancel: false,
      onDecline: props.onDecline,
      onCancel: props.onCancel,
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.showDeclineDialog({
        params: this.props.params,
        onDecline: this.onDecline,
        onCancel: this.onCancel,
      });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  onDecline = (params = {}) => {
    this.setState((prevState) => {
      return {
        decline: true,
        onDecline: prevState.onDecline.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  onCancel = (params = {}) => {
    this.setState((prevState) => {
      return {
        cancel: true,
        onCancel: prevState.onCancel.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  render() {
    return (
      <div>
        <div>{this.state.decline && this.state.onDecline.map(renderScenario)}</div>
        <div>{this.state.cancel && this.state.onCancel.map(renderScenario)}</div>
      </div>
    );
  }
}
