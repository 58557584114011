import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { declineButtonShow } from 'jsfcore/store/modules/viewport';
import { renderScenario } from '../scenariosUtils';

@connect(
  (state) => {
    return {
      triggerDeclineButtonClick: state.events.triggerDeclineButtonClick,
    };
  }, {
    declineButtonShow,
  },
)
export default class DeclineButtonShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['decline.button.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onClick: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,

    triggerDeclineButtonClick: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]).isRequired,
    declineButtonShow: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      clickStack: [],
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.declineButtonShow();
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ triggerDeclineButtonClick }) {
    if (triggerDeclineButtonClick !== this.props.triggerDeclineButtonClick) {
      this.setState((prevState) => {
        return {
          clickStack: [
            ...prevState.clickStack,
            ...this.props.onClick,
          ],
        };
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.clickStack.map(renderScenario)}
      </div>
    );
  }
}
