import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  addToolbarOverlay,
  setSigningSessionTextSet,
} from 'jsfcore/store/modules/viewport';
import { headerOverlayTypes, signingSessionTextSets } from 'jsfcore/helpers/const';
import { ifApply } from '../scenariosUtils';

@connect(
  null, {
    addToolbarOverlay,
    setSigningSessionTextSet,
  },
)
export default class ToolbarOverlayShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['toolbar.overlay.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      overlayType: ifApply(PropTypes.oneOf(Object.values(headerOverlayTypes))),
      textSets: ifApply(PropTypes.oneOf(Object.values(signingSessionTextSets))),
    }).isRequired,

    addToolbarOverlay: PropTypes.func.isRequired,
    setSigningSessionTextSet: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      const { overlayType, textSets = signingSessionTextSets.pdffiller } = this.props.params;

      this.props.addToolbarOverlay(overlayType);

      if (overlayType === headerOverlayTypes.signingSession) {
        this.props.setSigningSessionTextSet(textSets);
      }
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    return null;
  }
}
