import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import {
  activateFakeEdit,
  deactivateFakeEdit,
  showFakeEditInitialHint,
  hideFakeEditInitialHint,
  showFakeEditRolloverHint,
  hideFakeEditRolloverHint,
} from 'jsfcore/store/modules/viewport';
import { selectors } from 'jsfcore';
import { ifApply } from '../scenariosUtils';

@connect(
  (state) => {
    return {
      isFakeEditActive: selectors.base.getIsFakeEditActive(state),
      isFakeEditButtonVisible: selectors.fakeEdit.getIsFakeEditAvailable(state),
      isPdfBig: selectors.getIsPdfBig(state),
      hasPdfDocument: state.pdf.hasPdfDocument,
    };
  }, {
    activateFakeEdit,
    deactivateFakeEdit,
    showFakeEditInitialHint,
    hideFakeEditInitialHint,
    showFakeEditRolloverHint,
    hideFakeEditRolloverHint,
  },
)
export default class FakePdfEditButtonAppearance extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['fakePdfEdit.button.appearance']).isRequired,
    apply: PropTypes.bool.isRequired,
    runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      active: ifApply(PropTypes.bool),
      initialHintDuration: ifApply(PropTypes.number),
      showInitialHint: ifApply(PropTypes.bool),
      showRolloverHint: ifApply(PropTypes.bool),
    }).isRequired,
    isFakeEditActive: PropTypes.bool.isRequired,
    isFakeEditButtonVisible: PropTypes.bool.isRequired,
    isPdfBig: PropTypes.bool.isRequired,
    hasPdfDocument: PropTypes.bool.isRequired,
    activateFakeEdit: PropTypes.func.isRequired,
    deactivateFakeEdit: PropTypes.func.isRequired,
    showFakeEditInitialHint: PropTypes.func.isRequired,
    hideFakeEditInitialHint: PropTypes.func.isRequired,
    showFakeEditRolloverHint: PropTypes.func.isRequired,
    hideFakeEditRolloverHint: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (
      !this.props.apply ||
      !this.props.isFakeEditButtonVisible ||
      !this.props.runOnce
    ) {
      return;
    }

    if (this.props.hasPdfDocument) {
      this.runFun();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ hasPdfDocument }) {
    if (hasPdfDocument && !this.props.hasPdfDocument) {
      this.runFun();
    }
  }

  runFun = () => {
    if (this.props.delay > 0) {
      setTimeout(this.fun, this.props.delay);
    } else {
      this.fun();
    }
  };

  fun = () => {
    const {
      active,
      showInitialHint,
      initialHintDuration,
      showRolloverHint,
    } = this.props.params;
    const { isPdfBig, isFakeEditActive } = this.props;

    if (isFakeEditActive && isPdfBig) {
      this.props.deactivateFakeEdit();
    } else if (!isFakeEditActive && active && !isPdfBig) {
      this.props.activateFakeEdit(true);
    }

    showRolloverHint
      ? this.props.showFakeEditRolloverHint()
      : this.props.hideFakeEditRolloverHint();

    if (showInitialHint) {
      this.props.showFakeEditInitialHint();
      setTimeout(
        this.props.hideFakeEditInitialHint,
        initialHintDuration,
      );
    }

    if (showInitialHint === false) {
      this.props.hideFakeEditInitialHint();
    }
  };

  render() {
    return null;
  }
}
