/* eslint-disable no-underscore-dangle */
// В случае blur окна с запущенным таймером, остановим таймер.
// На focus снова запустим
const visibilitychange = 'visibilitychange';

export const timeOutThrowError = ({ modeId }) => {
  if (modeId) {
    throw new Error('The document load exceeded the allowable wait time');
  } else {
    throw new Error('No authorization server response');
  }
};

export default class AppTimeOut {
  constructor({ timeoutDuration, func }) {
    // document loading progress percent
    this._progress = 0;
    // ожидаемое время перед выполнением func
    this._timeoutDuration = timeoutDuration;
    // id timeout для очистки
    this._appTimeoutId = null;
    // функция которая будет выполнена по прошествию timeoutDuration
    this._func = func;

    this.activateAppTimeout();
  }

  _onVisibilityChanging = () => {
    if (document.hidden === true) {
      clearTimeout(this._appTimeoutId);
    }
    if (document.hidden === false) {
      this._activateAppTimeout();
    }
  };

  _addVisibilityChangeListener = () => {
    return document.addEventListener(visibilitychange, this._onVisibilityChanging);
  };

  _setProgress = (progress) => {
    this._progress = progress;
  };

  _activateAppTimeout = () => {
    clearTimeout(this._appTimeoutId);
    if (this.isWindowInBlurState()) {
      return;
    }
    this._appTimeoutId = setTimeout(() => {
      return this._func();
    }, this._timeoutDuration);
  };

  updateProgressAndRestart = (progress) => {
    if (this._progress < progress) {
      this._activateAppTimeout();
      this._setProgress(progress);
    }
  };

  activateAppTimeout = () => {
    this._addVisibilityChangeListener();
    this._activateAppTimeout();
  };

  deactivateAppTimeout = () => {
    clearTimeout(this._appTimeoutId);
    document.removeEventListener(visibilitychange, this._onVisibilityChanging);
  };

  isWindowInBlurState = () => {
    return !!document.hidden;
  };

  restartAppTimeout = () => {
    this._setProgress(0);
    this.deactivateAppTimeout();
    this.activateAppTimeout();
  };
}
