import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showFindAnotherFormBubble } from 'jsfcore/store/modules/viewport';

import { renderScenario } from '../scenariosUtils';

// Find Another Form (Bubble show)
// "action": "find_form.bubble.show",  идентификатор действия: показать баббл-хинт для кнопки
// "apply": true,  применять ли данное действие
// "runOnce": true,  запустить единоразово
// "delay": 5000,  задержка перед выполнением, (ms)
// "onShow":[]  событие показа окна, по которому запускается следующая цепочка

@connect(
  null, {
    showFindAnotherFormBubble,
  },
)
export default class FindFormBubbleShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['find_form.bubble.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onShow: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),

    showFindAnotherFormBubble: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onShow: [],
  };

  constructor(props) {
    super(props);
    this.state = { isShowed: false };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.showFindAnotherFormBubble();
      this.setState({ isShowed: true });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    const { onShow } = this.props;
    if (this.state.isShowed && onShow && onShow.length > 0) {
      return (
        <div>
          {onShow.map(renderScenario)}
        </div>
      );
    }

    return null;
  }
}
