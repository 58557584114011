export const goToLeftConditions = ({ caret, isDate, isFillable, isSafariDesktop }) => {
  if (caret === 0 || (isDate && isFillable) || (isDate && isSafariDesktop)) {
    return true;
  }

  return false;
};

export const goToRightConditions = ({ caret, size, isDate, isFillable }) => {
  if (caret === size || (isDate && isFillable)) {
    return true;
  }

  return false;
};
