import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InputFieldSetMain extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className="input-fieldset__main">
        {this.props.children}
      </div>
    );
  }
}
