import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fConstructorShow } from 'jsfcore/store/modules/viewport';

// Default Page (Show)

// "action": "constructor.show",  идентификатор действия: активировать конструктор
// "apply": false,  применять ли данное действие
// "runOnce": false,  запустить единоразово
// "delay": 0,  задержка перед выполнением, (ms)

@connect(
  (state) => {
    return {
      appStarted: state.events.appStarted,
    };
  }, {
    fConstructorShow,
  },
)
export default class ConstructorShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['constructor.show']).isRequired,
    delay: PropTypes.number.isRequired,
    apply: PropTypes.bool.isRequired,

    appStarted: PropTypes.bool.isRequired,
    fConstructorShow: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.appStarted) {
      this.run();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.appStarted && nextProps.appStarted) {
      this.run();
    }
  }

  run = () => {
    const initial = true;

    if (!this.props.apply) {
      return;
    }

    const { delay } = this.props;
    if (delay === 0) {
      this.props.fConstructorShow(initial);
    } else {
      setTimeout(() => {
        this.props.fConstructorShow(initial);
      }, delay);
    }
  }

  render() {
    return null;
  }
}
