import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showVideoHelpPreview } from 'combine-modals/lib/actions/actionCreator';
import { renderScenario } from '../scenariosUtils';

// Video Help (Preview show)
// "action": "video_help.preview.show",  идентификатор действия: показать диалог привью видео
// "apply": true,  применять ли данное действие
// "runOnce": true,  запустить единоразово
// "delay": 1000,  задержка перед выполнением, (ms)
// "onShow":[],  событие показа окна, по которому запускается следующая цепочка
// "onStart":[]  событие нажатия на кнопку проигрывания видео, по которому запускается
// следующая цепочка
// вызов диалога: store.dispatch(actionCreator.showVideoHelpPreview({onStart, onHide}))

@connect(
  null, {
    showVideoHelpPreview,
  },
)
export default class VideoHelpPreviewShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['video_help.preview.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onShow: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    onStart: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,

    showVideoHelpPreview: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onShow: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowed: false,
      isStarted: false,
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.showVideoHelpPreview({
        onHide: () => {},

        onStart: () => {
          this.setState({ isStarted: true });
        },
      });
      this.setState({ isShowed: true });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    const { onShow, onStart } = this.props;
    const { isShowed, isStarted } = this.state;

    return (
      <div>
        {onShow && <div>{isShowed && onShow.map(renderScenario)}</div>}
        {onStart && <div>{isStarted && onStart.map(renderScenario)}</div>}
      </div>
    );
  }
}
