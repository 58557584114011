import React from 'react';
import ScenariosComponentsMapContext from './ScenariosComponentsMapContext';

export const renderScenario = (scenario, index) => {
  return (
    <ScenariosComponentsMapContext.Consumer key={index}>
      {(scenariosComponentsMap) => {
        const ScenarioComponent = scenariosComponentsMap[scenario.action];
        if (!ScenarioComponent) {
          // eslint-disable-next-line
          console.warn(`${scenario.action} skipped`);
          return null;
        }

        return (
          <ScenarioComponent {...scenario} />
        );
      }}
    </ScenariosComponentsMapContext.Consumer>
  );
};

/**
 * Make validation function, that acts like 'isRequired'
 * only if we have (props.apply === true)
 *
 * @param {function} propType - PropTypes.[type] validation function
 * @returns {function} validation function
 */
// NOIE: arrow-functions are not supposed to be PropTypes validators
export function ifApply(propType) {
  return (props, propName, componentName, location, propFullName, ...rest) => {
    const value = props[propName] === null
      ? null
      : undefined;

    if (props.apply && (props[propName] === null || props[propName] === undefined)) {
      const errorString = `The ${location} '${propFullName || propName}' is marked \
as required in '${componentName}' with 'props.apply === true', but its value is \
'${value}'`;

      return new Error(errorString);
    }

    return propType(props, propName, componentName, location, propFullName, ...rest);
  };
}
