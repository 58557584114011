import React from 'react';

import FlexibleBarBody, {
  flexibleBarBodyJustifies,
  flexibleBarBodyOffsets,
} from 'jsfcore/ui/FlexibleBar/FlexibleBarBody';
import FlexibleBarCell from 'jsfcore/ui/FlexibleBar/FlexibleBarCell';
import Group, { groupOffsets } from 'jsfcore/ui/Group/Group';
import GroupBody from 'jsfcore/ui/Group/GroupBody';
import GroupItem from 'jsfcore/ui/Group/GroupItem';
import Text, { textSizes } from 'jsfcore/ui/Text';
import attributes from 'jsfcore/ui/attributes';

const RadioHeader = () => {
  return (
    <FlexibleBarBody
      justify={flexibleBarBodyJustifies.flexEnd}
      offset={flexibleBarBodyOffsets.medium}
    >
      <FlexibleBarCell attributes={{ ...attributes.grow }}>
        <Group offset={groupOffsets.smallHorizontal}>
          <GroupBody>
            <GroupItem>
              <Text id="ADD_RADIO_GROUP.MODAL_TITLE" size={textSizes[18]} />
            </GroupItem>
          </GroupBody>
        </Group>
      </FlexibleBarCell>
    </FlexibleBarBody>
  );
};

export default RadioHeader;
