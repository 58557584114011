import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showRequiredDialog } from 'combine-modals/lib/actions/actionCreator';
import { wizard, getLazy } from '@pdffiller/jsf-lazyload';
import { selectors } from 'jsfcore';
import { wizardFocusSelector } from 'jsfcore/helpers/selectors';
import { SKIPPED } from 'jsfcore/helpers/const/interface';
import { renderScenario } from '../scenariosUtils';

export class RequiredDialogShowComponent extends Component {
  static propTypes = {
    triggerOnDoneScenario: PropTypes.shape({
      callback: PropTypes.func,
    }).isRequired,
    apply: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({}).isRequired,
    onSave: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    onSkip: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,

    showRequiredDialog: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      saved: false,
      skipped: false,
      onSave: props.onSave,
      onSkip: props.onSkip,
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      this.onSkip();
      return;
    }

    const fun = () => {
      if (this.haveUnfilledRequiredFields()) {
        this.props.showRequiredDialog({
          params: this.props.params,
          onSave: this.onSave,
          onSkip: this.onSkip,
          onHide: this.onHide,
        });
      } else {
        this.onSkip();
      }
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  haveUnfilledRequiredFields = () => {
    const getListItems = getLazy(wizard.functions.getListItems);
    const state = this.context.store.getState();
    if (state.wizard) {
      const todoList = getListItems(
        selectors.elements.getElements(state),
        state.wizard.wizardItems,
        wizardFocusSelector(state),
        selectors.locale.getEditorLocale(state),
        selectors.navigation.getPagesSettings(state).map(({ source }) => {
          return source;
        }),
      );

      const unfilledRequiredElement = todoList.find(
        ({ required, done }) => {
          return required && !done;
        },
      );
      return unfilledRequiredElement !== undefined;
    }

    return false;
  };

  onSave = (params = {}) => {
    this.setState((prevState) => {
      return {
        saved: true,
        onSave: prevState.onSave.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  onSkip = (params = {}) => {
    this.setState((prevState) => {
      return {
        skipped: true,
        onSkip: prevState.onSkip.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  onHide = () => {
    if (this.props.triggerOnDoneScenario.callback) {
      this.props.triggerOnDoneScenario.callback(SKIPPED);
    }
  };

  render() {
    return (
      <div>
        <div>{this.state.saved && this.state.onSave.map(renderScenario)}</div>
        <div>{this.state.skipped && this.state.onSkip.map(renderScenario)}</div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      triggerOnDoneScenario: state.events.triggerOnDoneScenario,
    };
  }, {
    showRequiredDialog,
  },
)(RequiredDialogShowComponent);
