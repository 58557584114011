/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import validUrl from 'valid-url';
import { thisDevice } from '@pdffiller/jsf-useragent';

const inputId = 'tested-link-input';

const isValidURL = (url) => {
  return validUrl.isWebUri(url);
};

export default class HrefModalView extends Component {
  static propTypes = {
    hyperlink: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    onClickClose: PropTypes.func.isRequired,
    onClickRemove: PropTypes.func.isRequired,
    onClickTest: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const hyperlink = (props.hyperlink && props.hyperlink !== '')
      ? props.hyperlink
      : 'http://';

    this.inputStyle = (
      thisDevice.isFirefoxDesktop
        ? { MozUserSelect: 'text' }
        : {}
    );
    this.state = {
      text: hyperlink,
      isValid: isValidURL(hyperlink),
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.input.focus();
      this.input.select();
    }, 400);
  }

  storeRef = (ref) => {
    this.input = ref;
  };

  onClickTest = () => {
    this.props.onClickTest(this.state.text);
  };

  onClickSave = () => {
    this.props.onClickSave(this.state.text);
  };

  onChange = () => {
    this.setState({
      text: this.input.value,
      isValid: isValidURL(this.input.value),
    });
  };

  render() {
    return (
      <div className="modal-jsf">
        <div className="cm cm--md cm--system-action cm--system-action-tags">
          <i className="i i-close cm__close" />
          <header className="cm-header">
            <div className="cm-header__title">Hyperlink</div>
            <i
              className="i i-close cm__close"
              onClick={this.props.onClickClose}
            />
          </header>
          <div className="cm__body">
            <div className="test-link-widget">
              <label
                htmlFor={inputId}
                className="test-link-widget__label"
              >
                Please enter URL
              </label>
              <div className="test-link-widget__table">
                <div className="test-link-widget__cell">
                  <input
                    value={this.state.text}
                    onChange={this.onChange}
                    ref={this.storeRef}
                    id={inputId}
                    type="text"
                    placeholder="http://"
                    className="test-link-widget__input g-form-control g-form-control--sm"
                    style={this.inputStyle}
                  />
                </div>
                <div className="test-link-widget__cell test-link-widget__cell--control">
                  <button
                    type="button"
                    onClick={this.onClickTest}
                    disabled={!this.state.isValid}
                    className="test-link-widget__control g-btn g-btn--secondary g-btn--sm g-btn--auto-width"
                  >
                    Test
                  </button>
                </div>
              </div>
            </div>
            <div className="cm__footer cm__footer--text-right">
              <button
                type="button"
                className="g-btn g-btn--secondary g-btn--auto-width g-btn--sm"
                onClick={this.props.onClickRemove}
              >
                Remove
              </button>
              <button
                type="button"
                className="g-btn g-btn--primary g-btn--auto-width g-btn--sm"
                onClick={this.onClickSave}
                disabled={!this.state.isValid}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
