import React from 'react';
import PropTypes from 'prop-types';
import { LockModal, modalWrapperClassname } from 'jsfcore/helpers/const';
import MessageModal from 'combine-modals/lib/containers/modals/MessageModal';
import DialogModal from 'combine-modals/lib/containers/modals/DialogModal';
import InterfaceLockedModalWindow from 'jsfcore/components/InterfaceLockedModalWindow/InterfaceLockedModalWindow';
import { feedback, LazyComponent } from '@pdffiller/jsf-lazyload';

import Wrapper from 'combine-modals/lib/containers/Wrapper';
import * as modalTypes from 'combine-modals/lib/constants/modal.classnames.js';

import createModalLazyComponent from './createModalLazyComponent';
import withBoundary from './withBoundary';

// TODO: so hard
const {
  ALERT_MODAL,
  NEED_HELP_MODAL,
  HELP_MODAL,
  SEARCH_MODAL,
  VIDEO_MODAL,
  ERROR_MODAL,
  DIALOG_MODAL,
  WELCOME_MODAL,
  REQUIRED_FIELDS_MODAL,
  DECLINE_MODAL,
  MESSAGE_MODAL,
  FINALIZATION_MODAL,
  REPORT_MODAL,
  PHOTO_AUTH_MODAL,
  FEEDBACK_MODAL,
  FINAL_SCREEN_MODAL,
  NOTARIZE_MODAL,
} = modalTypes;

/* eslint-disable arrow-body-style */
const modalsToAttach = {
  [HELP_MODAL]: () => import('combine-modals/lib/containers/modals/HelpModal'),
  [NEED_HELP_MODAL]: () => import('combine-modals/lib/containers/modals/FormHelpModal'),
  [SEARCH_MODAL]: () => import('combine-modals/lib/containers/modals/SearchModal'),
  [VIDEO_MODAL]: () => import('combine-modals/lib/containers/modals/VideoModal'),
  [ALERT_MODAL]: () => import('combine-modals/lib/containers/modals/AlertModal'),
  [WELCOME_MODAL]: () => import('combine-modals/lib/containers/modals/WelcomeModal'),
  [REQUIRED_FIELDS_MODAL]: () => import('combine-modals/lib/containers/modals/RequiredFieldsModal'),
  [DECLINE_MODAL]: () => import('combine-modals/lib/containers/modals/DeclineModal'),
  [FINALIZATION_MODAL]: () => import('combine-modals/lib/containers/modals/FinalizationModal'),
  [PHOTO_AUTH_MODAL]: () => import('combine-modals/lib/containers/modals/PhotoAuthModal'),
  [REPORT_MODAL]: () => import('combine-modals/lib/containers/modals/ReportModal'),
  [FEEDBACK_MODAL]: () => import('combine-modals/lib/containers/modals/FeedbackModal'),
  [FINAL_SCREEN_MODAL]: () => import('combine-modals/lib/containers/modals/FinalScreenModal'),
  [ERROR_MODAL]: () => import('combine-modals/lib/containers/modals/ErrorModal'),
  [NOTARIZE_MODAL]: () => import('combine-modals/lib/containers/modals/NotarizeModal'),
};

const InterfaceLockedModalWindowWithBoundary =
  withBoundary(InterfaceLockedModalWindow, LockModal);
const MessageModalWindowWithBoundary =
  withBoundary(MessageModal, MESSAGE_MODAL);
const DialogModalWindowWithBoundary =
  withBoundary(DialogModal, DIALOG_MODAL);

const LazyModalComponents = Object.keys(modalsToAttach).map(
  modalId => ({
    modalId,
    Component: withBoundary(
      createModalLazyComponent({
        getLoadPromise: modalsToAttach[modalId],
        modalId,
      }),
      modalId,
    ),
  }),
);

const Modals = ({ isFeedbackLoaded }) => {
  return (
    <div className={modalWrapperClassname}>
      <Wrapper>
        {[
          ...LazyModalComponents.map(({ Component, modalId }) => {
            return <Component key={modalId} />;
          }),
          <InterfaceLockedModalWindowWithBoundary key={LockModal} />,
          <MessageModalWindowWithBoundary key={MESSAGE_MODAL} />,
          <DialogModalWindowWithBoundary key={DIALOG_MODAL} />,
        ]}
      </Wrapper>
      {isFeedbackLoaded &&
        <LazyComponent literal={feedback.components.feedbackModal} />
      }
    </div>
  );
};

Modals.propTypes = {
  isFeedbackLoaded: PropTypes.bool.isRequired,
};

export default Modals;
