import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { managers, dispatchAction } from '@pdffiller/jsf-lazyload';
import { selectors } from 'jsfcore';
import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';

import { renderScenario } from '../scenariosUtils';

// Signature show
// "action": "signatures.show" идентификатор действия: активировать галерею signatures,
//   если она доступна в данном режиме.
// "params":{} без параметров
// "onShow":[] событие открытия Signture диалога,
//   по которому запускается вложенная цепочка действий,
//   которые будут доступны только в пределах Signtures диалога. пока не предусмотрено.

@connect(
  (state) => {
    return {
      isManagersLoaded: lazySelectors.jsf.getIsManagersLoaded(state),
      isSignatureAvailable: selectors.getIsSignatureAvailable(state),
    };
  },
)
export default class SignatureManagerShow extends Component {
  static propTypes = {
    isManagersLoaded: PropTypes.bool.isRequired,
    isSignatureAvailable: PropTypes.bool.isRequired,

    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onShow: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  };

  static defaultProps = {
    onShow: [],
  };

  state = { isShowed: false };

  componentDidMount() {
    const { apply, isSignatureAvailable, isManagersLoaded } = this.props;
    if (!apply || !isSignatureAvailable) {
      return;
    }

    if (isManagersLoaded) {
      this.openSignatureManager();
    }
  }

  componentDidUpdate() {
    if (
      this.props.apply &&
      !this.state.isShowed &&
      this.props.isManagersLoaded &&
      this.props.isSignatureAvailable
    ) {
      this.openSignatureManager();
    }
  }

  openSignatureManager = () => {
    const fun = () => {
      dispatchAction(managers.actions.openSignatureManager, {});
      this.setState({ isShowed: true });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    const { onShow } = this.props;
    if (this.state.isShowed && onShow && onShow.length > 0) {
      return (
        <div>
          {onShow.map(renderScenario)}
        </div>
      );
    }

    return null;
  }
}
