import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DraggableCore } from 'react-draggable';
import { selectors } from 'jsfcore';
import DraggablePanel from 'jsfcore/components/DraggablePanel/DraggablePanel';
import DraggablePanelProvider from 'jsfcore/components/DraggablePanel/DraggablePanelProvider';
import StoreRefProvider from 'jsfcore/components/StoreRef/StoreRefProvider';
import { defaultSidesOfDraggablePanel, DRAGGABLE_ENTITIES } from 'jsfcore/helpers/const';
import SupportChatButtonView from './SupportChatButtonView';
import SupportChatButtonHint from './SupportChatButtonHint';

@connect(
  (state) => {
    return {
      appStarted: selectors.base.getAppStarted(state),
      isAirSlate: selectors.getIsAirSlate(state),
      loggedInViewerId: selectors.getLoggedInViewerId(state),
      showSupportChatButton: selectors.feature.getShowSupportChatButton(state),
      supportChatLocale: selectors.locale.getSupportChatLocale(state),
    };
  },
)
class SupportChatButton extends Component {
  static propTypes = {
    appStarted: PropTypes.bool.isRequired,
    isAirSlate: PropTypes.bool.isRequired,
    loggedInViewerId: PropTypes.string,
    showSupportChatButton: PropTypes.bool.isRequired,
    supportChatLocale: PropTypes.shape({
      hintMessage: PropTypes.string.isRequired,
      supportChatButtonAriaLabel: PropTypes.string.isRequired,
      closeHintButtonTitle: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    loggedInViewerId: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSupportChatLoaded: false,
      isSupportChatOpened: false,
    };
    this.chat = null;
  }

  componentDidMount() {
    if (!this.props.appStarted) {
      setTimeout(this.loadChat, 1000);
    }
  }

  getIsSupportButtonVisible = () => {
    const { appStarted, showSupportChatButton } = this.props;
    const { isSupportChatLoaded, isSupportChatOpened } = this.state;

    return (
      appStarted &&
      showSupportChatButton &&
      isSupportChatLoaded &&
      !isSupportChatOpened
    );
  };

  handleChatState = (isSupportChatOpened) => {
    if (!isSupportChatOpened) {
      return;
    }
    this.setState({ isSupportChatOpened });
  };

  initChat = () => {
    this.chat.setChatStateListener(this.handleChatState);
    this.chat.init(true);
  };

  loadChat = async () => {
    if (__CLIENT__ && !this.props.isAirSlate) {
      const { default: chat } = await import(
        /* webpackChunkName: "chat" */
        // eslint-disable-next-line comma-dangle
        'support-chat'
      );
      window.superChat = chat;
      this.chat = chat;
      this.initChat();
      this.setState({ isSupportChatLoaded: true });
    }
  };

  handleSupportChatButtonClick = () => {
    const options = this.props.loggedInViewerId
      ? { userId: this.props.loggedInViewerId }
      : {};
    this.chat.startLoad(false, options);
  };

  render() {
    const { supportChatLocale } = this.props;
    const {
      hintMessage,
      supportChatButtonAriaLabel,
      closeHintButtonTitle,
    } = supportChatLocale;
    const isSupportButtonVisible = this.getIsSupportButtonVisible();

    if (!isSupportButtonVisible) {
      return null;
    }

    return (
      <DraggablePanelProvider entity={DRAGGABLE_ENTITIES.supportChatButton}>
        {({
          workspace,
          defaultWorkspace,
          draggablePanelPosition,
          onChangePosition,
        }) => {
          return (
            <DraggablePanel
              defaultSide={defaultSidesOfDraggablePanel.bottomRight}
              headerSize={0}
              workspace={workspace}
              defaultWorkspace={defaultWorkspace}
              draggablePanelPosition={draggablePanelPosition}
              onChangePosition={onChangePosition}
            >
              {({
                top,
                left,
                isDragging,
                onDragStart,
                onDragMove,
                onDragStop,
                storeRef,
              }) => {
                return (
                  <StoreRefProvider proxy={storeRef}>
                    {({ getRefPromise, storeRef: storeButtonRef }) => {
                      return (
                        <Fragment>
                          <DraggableCore
                            onStart={onDragStart}
                            onDrag={onDragMove}
                            onStop={onDragStop}
                          >
                            <SupportChatButtonView
                              isAccessible={!isDragging}
                              supportChatButtonAriaLabel={supportChatButtonAriaLabel}
                              top={top}
                              left={left}
                              storeButtonRef={storeButtonRef}
                              onClickSupportChatButton={this.handleSupportChatButtonClick}
                            />
                          </DraggableCore>
                          <SupportChatButtonHint
                            hintMessage={hintMessage}
                            closeHintButtonTitle={closeHintButtonTitle}
                            getAnchorRefPromise={getRefPromise}
                          />
                        </Fragment>
                      );
                    }}
                  </StoreRefProvider>
                );
              }}
            </DraggablePanel>
          );
        }}
      </DraggablePanelProvider>
    );
  }
}

export default SupportChatButton;
