import React from 'react';
import PropTypes from 'prop-types';
import ScenariosComponentsMapContext from './ScenariosComponentsMapContext';

import FindFormButtonShow from './FindFormButtonShow/FindFormButtonShow';
import FindFormBubbleShow from './FindFormBubbleShow/FindFormBubbleShow';
import FindFormBubbleHide from './FindFormBubbleHide/FindFormBubbleHide';
import FindFormDialogShow from './FindFormDialogShow/FindFormDialogShow';
import WelcomeHelp from './WelcomeHelp/WelcomeHelp';
import WelcomeShow from './WelcomeShow/WelcomeShow';
import VideoHelpPreviewShow from './VideoHelpPreviewShow/VideoHelpPreviewShow';
import VideoHelpPreviewHide from './VideoHelpPreviewHide/VideoHelpPreviewHide';
import VideoPlaybackDialogShow from './VideoPlaybackDialogShow/VideoPlaybackDialogShow';
import DefaultPageShow from './DefaultPageShow/DefaultPageShow';
import MessageDialogShow from './MessageDialogShow/MessageDialogShow';
import MessageDialogHide from './MessageDialogHide/MessageDialogHide';
import MessageButtonShow from './MessageButtonShow/MessageButtonShow';
import DeclineButtonShow from './DeclineButtonShow/DeclineButtonShow';
import DraftButtonShow from './DraftButtonShow/DraftButtonShow';
import DeclineDialogShow from './DeclineDialogShow/DeclineDialogShow';
import DestroyExecute from './DestroyExecute/DestroyExecute';
import ToolbarOverlayShow from './ToolbarOverlayShow/ToolbarOverlayShow';
import ToolbarOverlayHide from './ToolbarOverlayHide/ToolbarOverlayHide';
import RequiredDialogShow from './RequiredDialogShow/RequiredDialogShow';
import FinalizationDialogShow from './FinalizationDialogShow/FinalizationDialogShow';
import PhotoAuthDialogShow from './PhotoAuthDialogShow/PhotoAuthDialogShow';
import ConstructorShow from './ConstructorShow/ConstructorShow';
import AutofillDialogShow from './AutofillDialogShow/AutofillDialogShow';
import RearrangeShow from './RearrangeShow/RearrangeShow';
import SignatureManagerShow from './SignatureManagerShow/SignatureManagerShow';
import ImageManagerShow from './ImageManagerShow/ImageManagerShow';
import FakePdfEditButtonAppearance from './FakePdfEditButtonAppearance/FakePdfEditButtonAppearance';

const scenariosComponentsMap = {
  'default_page.show': DefaultPageShow,
  'find_form.button.show': FindFormButtonShow,
  'find_form.bubble.show': FindFormBubbleShow,
  'find_form.bubble.hide': FindFormBubbleHide,
  'find_form.dialog.show': FindFormDialogShow,
  'help.show': WelcomeHelp,
  'video_help.preview.show': VideoHelpPreviewShow,
  'video_help.preview.hide': VideoHelpPreviewHide,
  'video_playback.dialog.show': VideoPlaybackDialogShow,
  'welcome.show': WelcomeShow,
  'message.dialog.show': MessageDialogShow,
  'message.dialog.hide': MessageDialogHide,
  'message.button.show': MessageButtonShow,
  'decline.button.show': DeclineButtonShow,
  'draft.button.show': DraftButtonShow,
  'decline.dialog.show': DeclineDialogShow,
  'destroy.execute': DestroyExecute,
  'toolbar.overlay.show': ToolbarOverlayShow,
  'toolbar.overlay.hide': ToolbarOverlayHide,
  'required.dialog.show': RequiredDialogShow,
  'finalization.dialog.show': FinalizationDialogShow,
  'photo_auth.dialog.show': PhotoAuthDialogShow,
  'constructor.show': ConstructorShow,
  'autofill.dialog.show': AutofillDialogShow,
  'rearrange.show': RearrangeShow,
  'signatures.show': SignatureManagerShow,
  'images.show': ImageManagerShow,
  'fakePdfEdit.button.appearance': FakePdfEditButtonAppearance,
};

const ScenariosComponentsMapProvider = ({ children }) => {
  return (
    <ScenariosComponentsMapContext.Provider value={scenariosComponentsMap}>
      {children}
    </ScenariosComponentsMapContext.Provider>
  );
};

ScenariosComponentsMapProvider.propTypes = {
  children: PropTypes.node,
};

ScenariosComponentsMapProvider.defaultProps = {
  children: null,
};

export default ScenariosComponentsMapProvider;
