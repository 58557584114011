import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { destroyByScenario } from 'ws-editor-lib/actions';
import { ifApply } from '../scenariosUtils';

@connect(
  null, {
    destroyByScenario,
  },
)
export default class DestroyExecute extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['destroy.execute']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      destroyType: ifApply(PropTypes.string),
      location: ifApply(PropTypes.string),
    }).isRequired,

    destroyByScenario: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.destroyByScenario(this.props.params, true);
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    return null;
  }
}
