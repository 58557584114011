import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectors } from 'jsfcore';
import ErrorModal from 'combine-modals/lib/containers/modals/ErrorModal';
import { destroy, trackPoint } from 'ws-editor-lib/actions';
import { thisDevice } from '@pdffiller/jsf-useragent';

const MODAL_ID = 'ErrorModalWrapper';

@connect(
  (state) => {
    return {
      exitExperimentUrl: state.ws.exitExperimentUrl,
      locale: selectors.locale.getEditorLocale(state),
    };
  }, {
    destroy,
    trackPoint,
  },
)
export default class ErrorModalWrapper extends Component {
  static propTypes = {
    exitExperimentUrl: PropTypes.string.isRequired,
    locale: PropTypes.shape({
      errorModal: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        contentMobile: PropTypes.string.isRequired,
        confirmMobile: PropTypes.string.isRequired,
        confirm: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,

    destroy: PropTypes.func.isRequired,
    trackPoint: PropTypes.func.isRequired,
  };

  static getModalId() {
    return MODAL_ID;
  }

  constructor(props) {
    super(props);
    this.modalId = ErrorModalWrapper.getModalId();
  }

  handleConfirm = () => {
    this.props.trackPoint('EXIT', { action: 'error' });
    try {
      this.props.destroy(this.props.exitExperimentUrl);
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }

    this.props.trackPoint('GO_TO_FLASH', {});
    setTimeout(() => {
      window.location.href = this.props.exitExperimentUrl;
    }, 300);
  };

  handleClose = () => {
    try {
      this.props.destroy(window.location.href);
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  render() {
    return (
      <ErrorModal
        title={this.props.locale.errorModal.title}
        content={thisDevice.isMobile
          ? this.props.locale.errorModal.contentMobile
          : this.props.locale.errorModal.content}

        btnConfirmText={thisDevice.isMobile
          ? this.props.locale.errorModal.confirmMobile
          : this.props.locale.errorModal.confirm}

        btnConfirmCallback={thisDevice.isMobile
          ? this.handleClose
          : this.handleConfirm}

        onCloseModal={this.handleClose}
      />
    );
  }
}
