import React from 'react';

import IntlProvider from 'jsfcore/jsf-localization/IntlProvider/IntlProvider';
import PageIdIndexContextProvider from 'jsfcore/components/PageIdIndexContextProvider';
import WrapperForFocusController from 'jsfcore/components/FocusController/WrapperForFocusController';
import Application from './components/Application/Application';

function LazyRoot() {
  return (
    <IntlProvider>
      <PageIdIndexContextProvider>
        <WrapperForFocusController>
          <Application />
        </WrapperForFocusController>
      </PageIdIndexContextProvider>
    </IntlProvider>
  );
}

export default LazyRoot;
