import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { trackPoint } from 'ws-editor-lib/actions';
import { appStartTimeoutLimit } from 'jsfcore/helpers/const';
import AppTimeOut, { timeOutThrowError } from 'jsfcore/helpers/AppTimeOut';
import logger from '@pdffiller/jsf-logger/clientLogger';
import getLoaderApi from '@pdffiller/jsf-loader/api';
import { selectors } from 'jsfcore';

@connect(
  (state) => {
    return {
      modeId: selectors.base.getModeId(state),
      loadingProgress: selectors.base.getLoadingProgress(state),
      appStarted: selectors.base.getAppStarted(state),
      source: selectors.base.getSource(state),
      fatalError: selectors.base.getFatalError(state),
      isEmbedded: selectors.base.getIsEmbedded(state),
    };
  }, {
    trackPoint,
  },
)
class AppStartTimeout extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    modeId: PropTypes.string,
    loadingProgress: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]).isRequired,
    appStarted: PropTypes.bool.isRequired,
    source: PropTypes.shape({
      pdf: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),

    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    fatalError: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]),
    isEmbedded: PropTypes.bool.isRequired,

    trackPoint: PropTypes.func.isRequired,
  };

  static defaultProps = {
    modeId: null,
    source: null,
    fatalError: false,
  };

  constructor(props) {
    super(props);
    // Activate timer on the application start. If through the time appStartTimeoutLimit
    // the application doesn't loading document(this is sometimes happens)
    // then throw the error.
    this.appStartTimeout = __CLIENT__ && !props.appStarted
      ? new AppTimeOut({
        timeoutDuration: appStartTimeoutLimit,
        func: () => {
          return timeOutThrowError({ modeId: this.props.modeId });
        },
      })
      : {
        deactivateAppTimeout: () => {},
        restartAppTimeout: () => {},
      };

    if (props.isEmbedded) {
      this.appStartTimeout.deactivateAppTimeout();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    loadingProgress,
    appStarted,
    modeId,
    source,
    fatalError,
  }) {
    const {
      isWindowInBlurState,
      updateProgressAndRestart,
      deactivateAppTimeout,
    } = this.appStartTimeout;

    const loadingProgressChanged = loadingProgress !== this.props.loadingProgress;
    const isAppStarted = appStarted && !this.props.appStarted;
    // если документ уже был загружен или мы применили rearrange или если
    // окно в состояние blur - не вызываем перезапуск таймаута
    if (
      loadingProgress &&
      loadingProgressChanged &&
      !appStarted &&
      !isWindowInBlurState()
    ) {
      updateProgressAndRestart(loadingProgress);
    }

    // Случай когда мы в 2-х броузерах открыли один и тот же документ, и в одном
    // нажали tryNow. Во втором произойдет ошибка editorAuth, и после
    // ошибка загрузки документа, по этой причине отключим таймер
    if (
      fatalError !== false ||
      (loadingProgress >= 100 && loadingProgressChanged)
    ) {
      deactivateAppTimeout();
    }

    if (isAppStarted) {
      this.props.trackPoint('EDITOR_STARTED', {});
      logger.logAppStart(modeId, source.pdf.url);
      getLoaderApi().finish();
      deactivateAppTimeout();
    }
  }

  render() {
    return this.props.children({
      appStartTimeout: this.appStartTimeout,
    });
  }
}

export default AppStartTimeout;
