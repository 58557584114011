import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DialogBody, { dialogBodySizes, dialogBodyThemes, dialogBodyBehaviors } from 'jsfcore/ui/Dialog/DialogBody';
import DialogMain from 'jsfcore/ui/Dialog/DialogMain';
import DialogHeader from 'jsfcore/ui/Dialog/DialogHeader';

const dialogStyles = {
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export default class Modals extends Component {
  static propTypes = {
    header: PropTypes.element.isRequired,
    footer: PropTypes.element.isRequired,
    props: PropTypes.shape({}).isRequired,
    children: PropTypes.element.isRequired,
  };

  render() {
    return (
      <DialogBody
        role="dialog"
        tabIndex="-1"
        style={dialogStyles}
        size={dialogBodySizes.small}
        theme={dialogBodyThemes.flatwhite}
        behavior={dialogBodyBehaviors.insetOffsets}
      >
        <DialogHeader>
          {this.props.header}
        </DialogHeader>

        <DialogMain>
          {this.props.children}
        </DialogMain>

        <div className="dialog__footer">
          {this.props.footer}
        </div>
      </DialogBody>
    );
  }
}
