import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FlexibleBarBody, {
  flexibleBarBodyOffsets,
  flexibleBarBodyJustifies,
} from 'jsfcore/ui/FlexibleBar/FlexibleBarBody';
import FlexibleBarCell from 'jsfcore/ui/FlexibleBar/FlexibleBarCell';
import Group, { groupTypes, groupOffsets, groupJustifies } from 'jsfcore/ui/Group/Group';
import GroupBody from 'jsfcore/ui/Group/GroupBody';
import GroupItem from 'jsfcore/ui/Group/GroupItem';
import Control, { controlSizes, controlThemes, controlOffsets, controlRoles } from 'jsfcore/ui/Control';
import Text, { textSizes } from 'jsfcore/ui/Text';

export default class RadioFooter extends Component {
  static propTypes = {
    isSelectButtonDisabled: PropTypes.bool.isRequired,

    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  render() {
    return (
      <FlexibleBarBody
        justify={flexibleBarBodyJustifies.flexEnd}
        offset={flexibleBarBodyOffsets.medium}
      >
        <FlexibleBarCell>
          <Group
            type={groupTypes.compact}
            offset={groupOffsets.small}
            justify={groupJustifies.spaceBetween}
          >
            <GroupBody>
              <GroupItem>
                <Control
                  onClick={this.props.onClose}
                  size={controlSizes.small}
                  theme={controlThemes.lackluster}
                  behavior={null}
                  justify={null}
                  role={controlRoles.null}
                  offset={controlOffsets.medium}
                  ariaLabel="Cancel"
                  isBusy={false}
                  isPressed={false}
                >
                  <Text id="FORMS.CANCEL" bold size={textSizes[14]} />
                </Control>
              </GroupItem>
              <GroupItem>
                <Control
                  disabled={this.props.isSelectButtonDisabled}
                  onClick={this.props.onSelect}
                  size={controlSizes.small}
                  theme={controlThemes.primary}
                  offset={controlOffsets.medium}
                  ariaLabel="Create"
                  isBusy={false}
                  isPressed={false}
                >
                  <Text id="ADD_RADIO_GROUP.SUBMIT_BUTTON" bold size={textSizes[14]} />
                </Control>
              </GroupItem>
            </GroupBody>
          </Group>
        </FlexibleBarCell>
      </FlexibleBarBody>
    );
  }
}
