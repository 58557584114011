import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showMessageDialog,
  hideMessageDialog,
} from 'combine-modals/lib/actions/actionCreator';
import {
  modalWrapperClassname,
  messageButtonHeaderId,
  mainHeaderId,
} from 'jsfcore/helpers/const';
import { selectors } from 'jsfcore';
import { getEventPath, hasClass } from 'jsfcore/helpers/utils';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { renderScenario, ifApply } from '../scenariosUtils';

export const desktopPos = {};

// Ширина диалога
export const messageDialogTabletWidth = 480;
export const messageDialogPhoneWidth = 280;

export const getMessageDialogUIOptions = ({
  headerClientRect, messageButtonClientRect, isTablet, isPhone,
}) => {
  const isMobile = isPhone || isTablet;

  if (isMobile && headerClientRect && messageButtonClientRect) {
    const width = isPhone
      ? messageDialogPhoneWidth
      : messageDialogTabletWidth;
    const xPositionForTC = Math.floor(
      headerClientRect.width - messageButtonClientRect.right -
      (width / 2),
    );

    if (xPositionForTC > -10) {
      return {
        y: 23,
        tailPosition: 'TC',
        x: xPositionForTC,
      };
    }

    return {
      y: 23,
      tailPosition: 'TR',
      x: headerClientRect.width - messageButtonClientRect.right,
    };
  }

  return desktopPos;
};

@connect(
  (state) => {
    return {
      appStarted: selectors.base.getAppStarted(state),
      isMessageButtonVisible: state.viewport.isMessageButtonVisible,
      messageButtonContent: selectors.base.getMessageButtonContent(state),
    };
  }, {
    showMessageDialog,
    hideMessageDialog,
  },
)
export default class MessageDialogShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['message.dialog.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    appStarted: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number,
    params: PropTypes.shape({
      avatarUrl: ifApply(PropTypes.string),
      message: ifApply(PropTypes.string),
      title: ifApply(PropTypes.string),
      videoId: ifApply(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
      ])),
    }).isRequired,
    onPlay: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    messageButtonContent: PropTypes.shape({}),
    isMessageButtonVisible: PropTypes.bool.isRequired,
    hideMessageDialog: PropTypes.func.isRequired,
    showMessageDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {
    messageButtonContent: null,
    delay: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      played: false,
      onPlay: props.onPlay,
    };
  }

  componentDidMount() {
    if (!this.props.apply || !this.props.isMessageButtonVisible) {
      return;
    }

    if (this.props.delay > 0) {
      setTimeout(this.fun, this.props.delay);
    } else {
      this.fun();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ isMessageButtonVisible, appStarted }) {
    if (!this.props.apply) {
      return;
    }

    if (
      appStarted && isMessageButtonVisible && (
        !this.props.isMessageButtonVisible ||
        !this.props.appStarted
      )
    ) {
      if (this.props.delay > 0) {
        setTimeout(this.fun, this.props.delay);
      } else {
        this.fun();
      }
    }
  }

  fun = () => {
    const params = this.props.messageButtonContent || this.props.params;
    this.props.showMessageDialog({
      params: {
        ...params,
        ...getMessageDialogUIOptions({
          headerClientRect:
            document.getElementById(mainHeaderId)
              ? document.getElementById(mainHeaderId).getBoundingClientRect()
              : false,
          messageButtonClientRect:
            document.getElementById(messageButtonHeaderId)
              ? document.getElementById(messageButtonHeaderId).getBoundingClientRect()
              : false,
          isTablet: thisDevice.isTablet,
          isPhone: thisDevice.isPhone,
        }),
      },
      onPlay: this.onPlay,
    });
    this.delegateEvents();
  };

  hideMessage = () => {
    this.props.hideMessageDialog();
    this.undelegateEvents();
  }

  delegateEvents() {
    document.addEventListener('click', this.onClickDocument);

    if (thisDevice.isTablet) {
      window.addEventListener('orientationchange', this.hideMessage);
    }
  }

  undelegateEvents() {
    document.removeEventListener('click', this.onClickDocument);

    if (thisDevice.isTablet) {
      window.removeEventListener('orientationchange', this.hideMessage);
    }
  }

  onPlay = (params = {}) => {
    this.setState((prevState) => {
      return {
        played: true,
        onPlay: prevState.onPlay.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  onClickDocument = (event) => {
    const func = (node) => {
      return hasClass(node, modalWrapperClassname);
    };
    if (getEventPath(event).findIndex(func) === -1) {
      this.props.hideMessageDialog();
      this.undelegateEvents();
    }
  };

  render() {
    if (this.state.played) {
      return (
        <div>{this.state.onPlay.map(renderScenario)}</div>
      );
    }

    return null;
  }
}
