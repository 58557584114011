import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CustomSettingsBody, { customSettingsOffsets } from 'jsfcore/ui/CustomSettings/CustomSettingsBody';
import CustomSettingsItem from 'jsfcore/ui/CustomSettings/CustomSettingsItem';
import Text, { textSizes, textWeights, textThemes, textBottomOffsets } from 'jsfcore/ui/Text';
import InputField, { inputFieldSizes, inputFieldThemes, inputFieldOffsets } from 'jsfcore/ui/InputField';
import * as EntitledBox from 'jsfcore/ui/EntitledBox';
import RadioGroup, { radioGroupTypes, radioGroupBehaviors, radioGroupOffsets } from 'jsfcore/ui/RadioGroup/RadioGroup';
import RadioGroupItem from 'jsfcore/ui/RadioGroup/RadioGroupItem';
import RadioButton, { radioButtonDataIndicators, radioButtonThemes } from 'jsfcore/ui/RadioButton';
import uiAttributes from 'jsfcore/ui/attributes';
import Checkbox from 'jsfcore/ui/Checkbox';
import uiLocale from 'jsfcore/ui/locales';

import { FormattedMessage } from '../../../jsf-localization';
import ErrorHint from '../../ui/ErrorHint';
import InputFieldSet from '../../ui/InputFieldSet';

const formattedGroupNameErrorsIds = [
  'ADD_RADIO_GROUP.GROUP_NAME_LABEL',
  'FORMS.GENERIC_REQUIRED',
];

export default class Radio extends Component {
  static propTypes = {
    groupName: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    isVerticalAlignment: PropTypes.bool.isRequired,
    groupValuesCount: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onRadioChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.groupNameInputRef = null;

    this.state = {
      isGroupNameOnceBlurred: false,
      isGroupValuesOnceBlurred: false,
    };
  }

  componentDidMount() {
    this.groupNameInputRef.focus();
  }

  setGroupNameInputRef = (ref) => {
    this.groupNameInputRef = ref;
  };

  getHasGroupNameError = () => {
    const { isGroupNameOnceBlurred } = this.state;
    const { groupName } = this.props;
    const trimmedGroupName = groupName.trim();
    const isSpaceInStartOrEndExists = trimmedGroupName.length !== groupName.length;
    return isGroupNameOnceBlurred && (isSpaceInStartOrEndExists || isEmpty(trimmedGroupName));
  };

  onGroupNameBlur = () => {
    if (this.state.isGroupNameOnceBlurred) {
      return;
    }

    this.setState({
      isGroupNameOnceBlurred: true,
    });
  };

  onGroupValuesBlur = () => {
    if (this.state.isGroupValuesOnceBlurred) {
      return;
    }

    this.setState({
      isGroupValuesOnceBlurred: true,
    });
  };

  onChangeRequired = (event) => {
    this.props.onChange('required', event.target.checked);
  };

  onChangeGroupName = (event) => {
    this.props.onChange('groupName', event.target.value);
  };

  onChangeGroupValues = (event) => {
    this.props.onChange('groupValues', event.target.value);
  };

  render() {
    const { isGroupValuesOnceBlurred } = this.state;
    const hasGroupNameError = this.getHasGroupNameError();
    const hasGroupValuesError = isGroupValuesOnceBlurred && this.props.groupValuesCount < 2;

    return (
      <CustomSettingsBody offset={customSettingsOffsets.large} separated>
        <CustomSettingsItem>
          <FormattedMessage id="FIELDS.REQUIRED_LABEL">
            {(formattedTitle) => {
              return (
                <Checkbox
                  onChange={this.onChangeRequired}
                  isChecked={this.props.required}
                >
                  <Text
                    id={formattedTitle}
                    size={textSizes[14]}
                    theme={textThemes.dovegray}
                  />
                </Checkbox>
              );
            }}
          </FormattedMessage>
        </CustomSettingsItem>

        <CustomSettingsItem>
          <InputFieldSet.Body
            behavior={InputFieldSet.behaviors.overflowText}
            isInvalid={hasGroupNameError}
            div
          >
            <InputFieldSet.Header>
              <Text
                id="ADD_RADIO_GROUP.GROUP_NAME_LABEL"
                weight={textWeights.bold}
                size={textSizes[14]}
                bold
              />
            </InputFieldSet.Header>
            <InputFieldSet.Main>
              <InputField
                size={inputFieldSizes.mediumStretched}
                theme={inputFieldThemes.whiteBordered}
                offset={inputFieldOffsets.medium}
                onChange={this.onChangeGroupName}
                value={this.props.groupName}
                onBlur={this.onGroupNameBlur}
                storeRef={this.setGroupNameInputRef}
              />
              {hasGroupNameError ? (
                <FormattedMessage id={formattedGroupNameErrorsIds}>
                  {(formattedGroupName, formattedIsRequired) => {
                    return (
                      <ErrorHint
                        errors={[{
                          errorText: `${formattedGroupName} ${formattedIsRequired}`,
                        }]}
                      />
                    );
                  }}
                </FormattedMessage>
              ) : null}
            </InputFieldSet.Main>
          </InputFieldSet.Body>
        </CustomSettingsItem>

        <CustomSettingsItem>
          <InputFieldSet.Body
            behavior={InputFieldSet.behaviors.null}
            isInvalid={hasGroupValuesError}
            div
          >
            <InputFieldSet.Header>
              <Text
                id="ADD_RADIO_GROUP.RADIO_VALUES_LABEL"
                weight={textWeights.bold}
                size={textSizes[14]}
                bold
              />
            </InputFieldSet.Header>
            <InputFieldSet.Main>
              <InputField
                onChange={this.onChangeGroupValues}
                onBlur={this.onGroupValuesBlur}
                tag="textarea"
                size={inputFieldSizes.extraLargeStretched}
                theme={inputFieldThemes.whiteBordered}
                offset={inputFieldOffsets.medium}
              />

              {isGroupValuesOnceBlurred && this.props.groupValuesCount === 0 ? (
                <FormattedMessage id="ADD_RADIO_GROUP.RADIO_VALUES_REQUIRED_ERROR">
                  {(errorText) => {
                    return (
                      <ErrorHint errors={[{ errorText }]} />
                    );
                  }}
                </FormattedMessage>
              ) : null}

              {isGroupValuesOnceBlurred && this.props.groupValuesCount === 1 ? (
                <FormattedMessage id="ADD_RADIO_GROUP.MORE_THAN_ONE_VALUE_ERROR">
                  {(errorText) => {
                    return (
                      <ErrorHint errors={[{ errorText }]} />
                    );
                  }}
                </FormattedMessage>
              ) : null}

              <InputFieldSet.AdditionalInfo>
                <Text id="ADD_RADIO_GROUP.RADIO_VALUES_DESCRIPTION" size={textSizes[13]} />
              </InputFieldSet.AdditionalInfo>
            </InputFieldSet.Main>
          </InputFieldSet.Body>
        </CustomSettingsItem>
        <CustomSettingsItem>
          <EntitledBox.Body>
            <EntitledBox.Header>
              <Text
                size={14}
                weight={textWeights.bold}
                bottomOffset={textBottomOffsets.extraSmall}
                id="ADD_RADIO_GROUP.RADIO_ALIGNMENT_LABEL"
              />
            </EntitledBox.Header>
            <EntitledBox.Main>
              <RadioGroup
                type={radioGroupTypes.compact}
                behavior={radioGroupBehaviors.wrapItems}
                offset={radioGroupOffsets.small}
              >
                <RadioGroupItem attributes={uiAttributes.disableGrow}>
                  <RadioButton
                    onChange={this.props.onRadioChange}
                    checked={this.props.isVerticalAlignment}
                    theme={radioButtonThemes.transparentGray}
                    dataIndicator={radioButtonDataIndicators.custom}
                    title={uiLocale.titles.radioVAlign}
                  >
                    <Text size={14} id="ADD_RADIO_GROUP.ALIGNMENT_VERTICAL_BUTTON" />
                  </RadioButton>
                </RadioGroupItem>
                <RadioGroupItem attributes={uiAttributes.disableGrow}>
                  <RadioButton
                    onChange={this.props.onRadioChange}
                    checked={!this.props.isVerticalAlignment}
                    theme={radioButtonThemes.transparentGray}
                    dataIndicator={radioButtonDataIndicators.custom}
                    title={uiLocale.titles.radioHAlign}
                  >
                    <Text size={14} id="ADD_RADIO_GROUP.ALIGNMENT_HORIZONTAL_BUTTON" />
                  </RadioButton>
                </RadioGroupItem>
              </RadioGroup>
            </EntitledBox.Main>
          </EntitledBox.Body>
        </CustomSettingsItem>
      </CustomSettingsBody>
    );
  }
}
