import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { thisDevice } from '@pdffiller/jsf-useragent';
import config from 'jsfcore/helpers/clientConfig';
import { nullComponent } from 'jsfcore/helpers/utilsForReact15';
import { selectors, thunks } from 'jsfcore';

const minScreenStyle = { display: 'flex' };


@connect(
  (state) => {
    return {
      locale: selectors.locale.getSmallScreenMessage(state),
      isSignatureManagerShown: selectors.base.getIsSignatureManagerShown(state),
      isImageManagerShown: selectors.base.getIsImageManagerShown(state),
    };
  },
  {
    deactivateElement: thunks.deactivateElement,
  },
)
export default class SmallScreenMessage extends Component {
  static propTypes = {
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      landscapeTitle: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    isSignatureManagerShown: PropTypes.bool.isRequired,
    isImageManagerShown: PropTypes.bool.isRequired,
    deactivateElement: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    // Комопнент не перерендеривается, если не обновляются state, props
    // и уже отрисован с помощью SSR
    // Поэтому устанавливаю this.state.landscape = false, а затем обновляю, если необходимо
    this.state = {
      landscape: false,
    };
  }

  componentDidMount() {
    this.onOrientationChange();
    if (__CLIENT__ && thisDevice.isPhone) {
      window.addEventListener('orientationchange', this.onOrientationChange);
    }
  }

  isLandscape = () => {
    return (
      __CLIENT__ &&
      thisDevice.isPhone &&
      (
        window.orientation === 90 ||
        window.orientation === -90
      )
    );
  };

  onOrientationChange = () => {
    const landscape = this.isLandscape();
    if (landscape) {
      this.props.deactivateElement();
    }
    this.setState({
      landscape,
    });
  };

  render() {
    const { locale, isSignatureManagerShown, isImageManagerShown } = this.props;
    const { landscape } = this.state;

    const iconClass = thisDevice.isPhone
      ? 'icon-svg-rotate-phone'
      : 'icon-svg-resize-window';

    const style = landscape && !isSignatureManagerShown && !isImageManagerShown
      ? minScreenStyle
      : null;

    if (!config.app.showSmallScreenMessage && !thisDevice.isPhone) {
      return nullComponent();
    }

    return (
      <div
        className="jsf-min-screen"
        style={style}
      >
        <div className="jsf-min-screen__inner">
          <div className={classnames('jsf-min-screen__icon', iconClass)} />
          <div className="jsf-min-screen__title">
            {
              thisDevice.isPhone
                ? locale.landscapeTitle
                : locale.title
            }
          </div>
          {!thisDevice.isPhone &&
            <div className="jsf-min-screen__desc">{locale.description}</div>
          }
        </div>
      </div>
    );
  }
}
