import React from 'react';
import PropTypes from 'prop-types';

import Text, { textSizes } from 'jsfcore/ui/Text';
import Popover, {
  popoverThemes,
  popoverArrowPositions,
  popoverSizes,
  popoverOffsets,
} from 'jsfcore/ui/Popover/Popover';
import PopoverBody from 'jsfcore/ui/Popover/PopoverBody';


const popoverStyles = {
  position: 'absolute',
  left: 'calc(100% + 10px)',
  top: '50%',
  transform: 'translateY(-50%)',
};

const addDelimitersReduce = (acc, error, index, array) => {
  acc.push(error);
  if (array.length - 1 > index) {
    acc.push(
      // eslint-disable-next-line
      <Text size={textSizes[12]} center key={`delimiter-${index}`}>
        .&nbsp;
      </Text>,
    );
  }
  return acc;
};

const ErrorHint = ({ errors }) => {
  return (
    <Popover
      theme={popoverThemes.error}
      arrowPosition={popoverArrowPositions.leftCenter}
      size={popoverSizes.small}
      offset={popoverOffsets.small}
      style={popoverStyles}
    >
      <PopoverBody>
        {
          errors.map(({ errorText, interpolationParams }) => {
            return (
              <Text
                size={textSizes[12]}
                center
                id={errorText}
                key={errorText}
                interpolationParams={interpolationParams}
              />
            );
          }).reduce(addDelimitersReduce, [])
        }
      </PopoverBody>
    </Popover>
  );
};

ErrorHint.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      errorText: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default ErrorHint;
