import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { removeToolbarOverlay } from 'jsfcore/store/modules/viewport';
import { ifApply } from '../scenariosUtils';

@connect(
  null, {
    removeToolbarOverlay,
  },
)
export default class ToolbarOverlayHide extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['toolbar.overlay.hide']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      overlayType: ifApply(PropTypes.oneOf([
        'wizard', 'read_only',
      ])),
    }).isRequired,

    removeToolbarOverlay: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.removeToolbarOverlay(this.props.params.overlayType);
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    return null;
  }
}
