import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectors } from '../..';
import ModalBackdrop from '../ui/ModalBackdrop';

@connect(
  (state) => {
    return {
      isRadioModalVisible: selectors.modals.getIsRadioModalVisible(state),
      isRadioRemoveModalVisible: selectors.modals.getIsRadioRemoveModalVisible(state),
      isConfirmationModalVisible: selectors.modals.getIsConfirmationModalVisible(state),
      isWarningModalVisible: selectors.modals.getIsWarningModalVisible(state),
    };
  },
)
export default class ModalsBackdrop extends Component {
  static propTypes = {
    isRadioModalVisible: PropTypes.bool.isRequired,
    isRadioRemoveModalVisible: PropTypes.bool.isRequired,
    isConfirmationModalVisible: PropTypes.bool.isRequired,
    isWarningModalVisible: PropTypes.bool.isRequired,
  };

  render() {
    if (
      this.props.isRadioModalVisible ||
      this.props.isRadioRemoveModalVisible ||
      this.props.isConfirmationModalVisible ||
      this.props.isWarningModalVisible
    ) {
      return (
        <ModalBackdrop />
      );
    }

    return null;
  }
}
