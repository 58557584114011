import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showWelcomeDialog } from 'combine-modals/lib/actions/actionCreator';

import { renderScenario, ifApply } from '../scenariosUtils';

@connect(
  null, {
    showWelcomeDialog,
  },
)
export default class WelcomeShow extends Component {
  static propTypes = {
    apply: PropTypes.bool.isRequired,
    delay: ifApply(PropTypes.number),
    params: PropTypes.shape({
      customLogoUrl: ifApply(PropTypes.string),
      documentAccess: ifApply(PropTypes.string),
      editType: ifApply(PropTypes.string),
      fillableFieldsMode: ifApply(PropTypes.string),
      recipientName: ifApply(PropTypes.string),
      senderName: ifApply(PropTypes.string),
    }).isRequired,
    onHide: PropTypes.arrayOf(PropTypes.object).isRequired,

    showWelcomeDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {
    delay: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      isHidden: false,
      onHide: props.onHide,
    };
  }

  componentDidMount() {
    const { apply, params } = this.props;

    if (!apply) {
      return;
    }

    const fun = () => {
      this.props.showWelcomeDialog({
        params,
        onHide: this.onHide,
      });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  onHide = (params = {}) => {
    this.setState((prevState) => {
      return ({
        isHidden: true,
        onHide: prevState.onHide.map((el) => {
          return ({
            ...el,
            params: { ...el.params, ...params },
          });
        }),
      });
    });
  };

  render() {
    const { onHide } = this.state;

    if (this.state.isHidden && onHide.length > 0) {
      return (
        <div>
          {onHide.map(renderScenario)}
        </div>
      );
    }

    return null;
  }
}
