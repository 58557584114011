import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import Icon, { iconSizes, iconTypes } from 'jsfcore/ui/Icon';

const SupportChatButtonView = (props) => {
  const {
    isAccessible,
    supportChatButtonAriaLabel,
    top,
    left,
    style,
  } = props;

  return (
    <div
      className="chat-button"
      style={{
        top,
        left,
        ...style,
      }}
      ref={props.storeButtonRef}
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      onMouseUp={props.onMouseUp}
      onTouchEnd={props.onTouchEnd}
    >
      <button
        type="button"
        className={cls('chat-button__body', {
          _inaccessible: !isAccessible,
        })}
        aria-label={supportChatButtonAriaLabel}
        onClick={props.onClickSupportChatButton}
      >
        <span className="chat-button__icon">
          <Icon
            type={iconTypes.redesignColoredChat}
            size={iconSizes.parent}
          />
        </span>
      </button>
    </div>
  );
};

SupportChatButtonView.propTypes = {
  isAccessible: PropTypes.bool.isRequired,
  supportChatButtonAriaLabel: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  storeButtonRef: PropTypes.func.isRequired,
  onClickSupportChatButton: PropTypes.func.isRequired,

  // from DraggableCore
  style: PropTypes.shape({}),
  onMouseDown: PropTypes.func,
  onTouchStart: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
};

SupportChatButtonView.defaultProps = {
  style: null,
  onMouseDown: null,
  onTouchStart: null,
  onMouseUp: null,
  onTouchEnd: null,
};

export default SupportChatButtonView;
