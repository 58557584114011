import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  hideMessageDialog,
} from 'combine-modals/lib/actions/actionCreator';

@connect(
  null, {
    hideMessageDialog,
  },
)
export default class MessageDialogHide extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['message.dialog.hide']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,

    hideMessageDialog: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.hideMessageDialog();
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  render() {
    return null;
  }
}
