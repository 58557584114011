import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Warning from './Warning';
import Confirmation from './Confirmation';
import { RadioProvider } from './Radio';
import { selectors } from '../..';
import Dialog from '../ui/Dialog';

@connect(
  (state) => {
    return {
      isConfirmationModalVisible: selectors.modals.getIsConfirmationModalVisible(state),
      isWarningModalVisible: selectors.modals.getIsWarningModalVisible(state),
      isRadioModalVisible: selectors.modals.getIsRadioModalVisible(state),
      isRadioRemoveModalVisible: selectors.modals.getIsRadioRemoveModalVisible(state),
    };
  },
)
export default class Modals extends Component {
  static propTypes = {
    isConfirmationModalVisible: PropTypes.bool.isRequired,
    isWarningModalVisible: PropTypes.bool.isRequired,
    isRadioModalVisible: PropTypes.bool.isRequired,
    isRadioRemoveModalVisible: PropTypes.bool.isRequired,
  };

  getModalProvider = () => {
    if (this.props.isConfirmationModalVisible) {
      return Confirmation;
    }

    if (this.props.isWarningModalVisible) {
      return Warning;
    }

    if (this.props.isRadioModalVisible) {
      return RadioProvider;
    }

    if (this.props.isRadioRemoveModalVisible) {
      return ({ children }) => {
        return children({
          onClose: () => {},
          header: null,
          footer: null,
          component: null,
        });
      };
    }

    return false;
  }

  render() {
    const Provider = this.getModalProvider();


    if (Provider) {
      return (
        <Provider>
          {({ onClose, header, footer, component, props }) => {
            return (
              <Dialog
                onClose={onClose}
                header={header}
                footer={footer}
                props={props}
              >
                {component}
              </Dialog>
            );
          }}
        </Provider>
      );
    }

    return null;
  }
}
