import React, { Component } from 'react';
import ErrorBoundary from '../Application/Error/ErrorBoundary';

export default (Modal, modalId) => {
  class ModalWithBoundary extends Component {
    static getModalId() {
      return modalId;
    }

    render() {
      return (
        <ErrorBoundary>
          <Modal />
        </ErrorBoundary>
      );
    }
  }
  ModalWithBoundary.displayName = Modal.name;

  return ModalWithBoundary;
};
