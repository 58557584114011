import { Component, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import logger from '@pdffiller/jsf-logger/clientLogger';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  state = {
    hasError: false,
  }

  // this static method is required by required
  // otherwise we will get warning
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    logger.logReactRenderError(error);
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    const { children, ...props } = this.props;
    return cloneElement(Children.only(children), props);
  }
}
