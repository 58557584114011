import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showSimpleVideo } from 'combine-modals/lib/actions/actionCreator';
import { renderScenario, ifApply } from '../scenariosUtils';


// Video Help (Start)
// "action":"video_help.video.start",  идентификатор действия: запустить видео
// "apply": true,  применять ли данное действие
// "runOnce": true,  запустить единоразово
// "delay": 0,  задержка перед выполнением, (ms)
// "videoId": "WKRI23GH",  youtube id (передаваемое в диалог для воспроизведения)
// "minimizable": true,  cворачиваемое окно (передаваемое в диалог для воспроизведения)
// "startPoint": 0,  точка старта (передаваемое в диалог для воспроизведения)
// "endPoint": 0,  точка окончания (передаваемое в диалог для воспроизведения)
// "onHide":[]  событие закрытия окна, по которому запускается следующая цепочка.

// вызов диалога: store.dispatch(actionCreator.showSimpleVideo({videoId, minimizable,
// startPoint, endPoint, onHide}))

// 1. изменился идентификатор окна запуска видео
// Был: video_help.preview.show
// Стал: video_playback.dialog.show
// 2. параметры определяющие видео контент завернуты в "params"
// 3. изменилась сигнатура вызова диалога
// Обновленная дока: https://pdffiller.atlassian.net/wiki/display/PD/Video+Playback

@connect(
  null, {
    showSimpleVideo,
  },
)
export default class VideoPlaybackDialogShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['video_playback.dialog.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      autostart: ifApply(PropTypes.bool),
      videoId: ifApply(PropTypes.string),
      minimizable: ifApply(PropTypes.bool),
      startPoint: ifApply(PropTypes.number),
      endPoint: ifApply(PropTypes.number),
    }).isRequired,

    onHide: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),

    showSimpleVideo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onHide: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      onHide: props.onHide,
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.showSimpleVideo({
        params: this.props.params,
        onHide: this.onHide,
      });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  onHide = (params = {}) => {
    this.setState((prevState) => {
      return {
        isHidden: true,
        onHide: prevState.onHide.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
  };

  render() {
    const { isHidden, onHide } = this.state;

    if (onHide && onHide.length > 0 && isHidden) {
      return (
        <div>{onHide.map(renderScenario)}</div>
      );
    }

    return null;
  }
}
