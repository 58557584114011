import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectors } from 'jsfcore';
import { connectionStatuses, systemStatuses } from 'jsfcore/helpers/const';
import { stopEvent } from 'jsfcore/helpers/utils';
import getLoaderApi from '@pdffiller/jsf-loader/api';
import { getAccess } from 'ws-editor-lib/actions';
import { sendPostMessage } from 'ws-editor-lib/store/helpers';
import { POST_MESSAGES } from 'ws-editor-lib/constants';
import TryNowView from './TryNowView';

const isLoaderFinished = () => {
  return getLoaderApi().isFinished();
};

@connect(
  (state) => {
    return {
      status: selectors.base.getAccessStatus(state),
      cancelUrl: selectors.base.getAccessCancelUrl(state),
      busyUser: selectors.base.getAccessBusyUser(state),
      accessTimer: selectors.base.getAccessTimer(state),
      autoCheck: selectors.base.getAutoCheck(state),
      locale: selectors.locale.getEditorLocale(state).tryNow,
      connectionStatus: selectors.base.getConnectionStatus(state),
      systemStatus: selectors.base.getSystemStatus(state),
      isBusyAccessStatus: selectors.getIsBusyAccessStatus(state),
      isAirSlate: selectors.getIsAirSlate(state),
    };
  }, {
    getAccess,
  },
)
export default class TryNow extends Component {
  static propTypes = {
    connectionStatus: PropTypes.string.isRequired,
    systemStatus: PropTypes.string.isRequired,
    isBusyAccessStatus: PropTypes.bool.isRequired,
    status: PropTypes.string,
    cancelUrl: PropTypes.string.isRequired,
    busyUser: PropTypes.string,
    accessTimer: PropTypes.number,
    autoCheck: PropTypes.bool,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      editedBy: PropTypes.string.isRequired,
      beforeTimer: PropTypes.string.isRequired,
      afterTimer: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      tryNow: PropTypes.string.isRequired,
    }).isRequired,
    appStartTimeout: PropTypes.shape({
      deactivateAppTimeout: PropTypes.func.isRequired,
      restartAppTimeout: PropTypes.func.isRequired,
    }).isRequired,
    isAirSlate: PropTypes.bool.isRequired,
    getAccess: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autoCheck: true,
    status: null,
    busyUser: null,
    accessTimer: 60,
  };

  state = {
    wasBtnClicked: false,
  };

  componentDidMount() {
    if (this.props.isBusyAccessStatus && !isLoaderFinished()) {
      getLoaderApi().hide();
      this.props.appStartTimeout.deactivateAppTimeout();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ status, isBusyAccessStatus }) {
    if (this.props.status !== status) {
      if (isBusyAccessStatus && !isLoaderFinished()) {
        getLoaderApi().hide();
        this.props.appStartTimeout.deactivateAppTimeout();
      }

      if (!isBusyAccessStatus && !isLoaderFinished()) {
        getLoaderApi().show();
      }
    }
  }

  onClickCancel = (event) => {
    stopEvent(event);
    this.setState({ wasBtnClicked: true });

    if (this.props.isAirSlate) {
      sendPostMessage(POST_MESSAGES.unexpectedDestroy);
    } else {
      window.location.href = this.props.cancelUrl;
    }
  };

  onClickTry = (event) => {
    stopEvent(event);
    this.setState({ wasBtnClicked: true });

    this.props.getAccess();
    this.props.appStartTimeout.restartAppTimeout();
  };

  render() {
    const {
      busyUser,
      accessTimer,
      connectionStatus,
      locale,
      autoCheck,
      systemStatus,
      isBusyAccessStatus,
    } = this.props;
    const isConnectOrNoneConnectionStatus =
      connectionStatus === connectionStatuses.connect ||
      connectionStatus === connectionStatuses.none;
    const isReconnect = isConnectOrNoneConnectionStatus && systemStatus === systemStatuses.none;

    if (
      !isBusyAccessStatus ||
      connectionStatus === connectionStatuses.disconnect ||
      isReconnect
    ) {
      return null;
    }

    return (
      <TryNowView
        autoCheck={autoCheck}
        accessTimer={accessTimer}
        onClickCancel={this.onClickCancel}
        onClickTry={this.onClickTry}
        email={busyUser}
        locale={locale}
        disabled={this.state.wasBtnClicked}
      />
    );
  }
}
