import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from 'jsfcore/helpers/clientConfig';
import { thisDevice } from '@pdffiller/jsf-useragent';
import classnames from 'classnames';
import { selectors } from 'jsfcore';

// const styles = require('./DevBuildLabel.css');

@connect(
  (state) => {
    return {
      modeId: selectors.base.getModeId(state),
    };
  },
)
export default class DevBuildLabel extends Component {
  static propTypes = {
    modeId: PropTypes.string,
  }

  static defaultProps = {
    modeId: null,
  }

  constructor(props) {
    super(props);
    this.state = { version: '' };
  }

  componentDidMount() {
    this.updateVersion(this.props.modeId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modeId !== this.props.modeId) {
      this.updateVersion(nextProps.modeId);
    }
  }

  updateVersion = (modeId) => {
    this.setState({
      version: `
        mode: ${modeId || 'null'}; build: ${config.build || 'null'};        
      `,
    });
  }

  renderLine = (line, idx) => {
    return (
      <span className="devBuildLine" key={idx}>{line}</span>
    );
  };

  render() {
    const { version } = this.state;
    if (version && thisDevice.isMobile) {
      const lines = version.split(';');
      return (
        <div
          className={classnames('devBuildLabel', {
            devBuildHidden: !config.app.showDevBuild,
          })}
        >
          {lines.map(this.renderLine)}
        </div>
      );
    }

    return (
      <div
        className={classnames('devBuildLabel', {
          devBuildHidden: !config.app.showDevBuild,
        })}
      >
        <span>{version}</span>
      </div>
    );
  }
}
