import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { trackPoint } from 'ws-editor-lib/actions';
import { selectors } from 'jsfcore';
import {
  showFindAnotherFormModal,
} from 'combine-modals/lib/actions/actionCreator';

import { renderScenario, ifApply } from '../scenariosUtils';

// Find Another Form (Dialog show)
// "action": "find_form.dialog.show",  идентификатор действия: показать диалог поиска форм
// "apply": true,  применять ли данное действие
// "runOnce": false,  запускать многократно
// "delay": 0,  задержка перед выполнением, (ms)
// "queryUrl": "https://www.pdffiller.com/en/search.htm?s=5&q="  адрес, на который переходить,
// для поиска другой формы, текст из диалога добавляется в конец ссылки. Перед переходом важно
// отправить destroy c этой ссылкой. Далее отработает уже существующая механика по выходу из эдитора

// вызов диалога: store.dispatch(actionCreator.showFindAnotherFormModal({onSearch, onHide})),
// где: в onSearch передастся строка для поиска, например "W9".

// Изменения коснулись только узла: "find_form.dialog.show"
//
// 1. Параметр "queryUrl" переименовался в "location", и обвернулся в "params".
// 2. При нажатии на Search должно срабатывать событие onSearch с передачей в
// него модифицированного объекта "params"
// 3. Теперь диалог не запускает дестрой самостоятельно, будет проходить через
// специальный destroy узел.
//
// Обновленная дока: https://pdffiller.atlassian.net/wiki/display/PD/Find+Another+Form

@connect(
  (state) => {
    return {
      locale: selectors.locale.getSearchModalLocale(state),
    };
  }, {
    trackPoint,
    showFindAnotherFormModal,
  },
)
export default class FindFormDialogShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['find_form.dialog.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      location: ifApply(PropTypes.string),
    }).isRequired,
    onSearch: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      primaryButton: PropTypes.string.isRequired,
    }).isRequired,

    trackPoint: PropTypes.func.isRequired,
    showFindAnotherFormModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchStarted: false,
      onSearch: props.onSearch,
    };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.showFindAnotherFormModal({
        params: this.props.params,
        onHide: this.onHide,
        onSearch: this.onSearch,
        locale: this.props.locale,
      });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  onHide = () => {};

  onSearch = (params) => {
    this.setState((prevState) => {
      return {
        searchStarted: true,
        onSearch: prevState.onSearch.map((el) => {
          return {
            ...el,
            params: { ...el.params, ...params },
          };
        }),
      };
    });
    this.props.trackPoint('EXIT', { action: 'find' });
  };

  render() {
    const { searchStarted, onSearch } = this.state;

    if (searchStarted && onSearch.length > 0) {
      return (
        <div>{onSearch.map(renderScenario)}</div>
      );
    }

    return null;
  }
}
