const validateDatabaseName = (inputValue = '') => {
  // first letter should be letter or underscore
  const firstValidIndex = inputValue.search(/[a-zA-Z_]/);
  if (firstValidIndex === -1) {
    return '';
  }

  // other letters should be letter, digit, underscore, hyphen or space
  return inputValue
    .slice(firstValidIndex)
    .match(/[\w-]+\s?/g)
    .join('');
};

export default validateDatabaseName;
