import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectors, thunks } from 'jsfcore';
import * as Ui from 'jsfcore/ui';
import { doAutofill } from 'ws-editor-lib/actions';
import editorModes from 'ws-editor-lib/constants/editorModes';
import { showFinalizationDialog, openModal } from 'combine-modals/lib/actions/actionCreator';
import { DIALOG_MODAL } from 'combine-modals/lib/constants/modal.classnames';
import constants from 'combine-modals/lib/constants/dialog.modal';
import { ifApply } from '../scenariosUtils';

const AUTOFILL_EDIT_TYPE = 'autofill';
const icon = (
  <Ui.Grid.Flex
    type={Ui.grid.types.compact}
    offset={Ui.grid.offsets.small}
    justify={Ui.grid.justifies.center}
  >
    <Ui.Grid.FlexRow>
      <Ui.Grid.FlexCell attributes={Ui.attributes.grow}>
        <Ui.Icon
          type={Ui.icon.types.coloredAutofill}
          size={Ui.icon.sizes.huge}
        />
      </Ui.Grid.FlexCell>
    </Ui.Grid.FlexRow>
  </Ui.Grid.Flex>
);

@connect(
  (state) => {
    return {
      appStarted: selectors.base.getAppStarted(state),
      editorMode: selectors.base.getMode(state),
      activeElementId: selectors.base.getActiveElementId(state),
      autofillModalLocale: selectors.locale.getAutofillModalLocale(state),
      loadingModalLocale: selectors.locale.getLoadingModalLocale(state),
    };
  }, {
    setActiveElement: thunks.setActiveElement,
    showFinalizationDialog,
    openModal,
    doAutofill,
  },
)
export default class AutofillDialogShow extends Component {
  static propTypes = {
    apply: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    params: PropTypes.shape({
      fromProject: ifApply(PropTypes.number),
    }).isRequired,
    appStarted: PropTypes.bool.isRequired,
    editorMode: PropTypes.string,
    activeElementId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    autofillModalLocale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      cancelButtonText: PropTypes.string.isRequired,
      okButtonText: PropTypes.string.isRequired,
    }).isRequired,
    loadingModalLocale: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,

    setActiveElement: PropTypes.func.isRequired,
    showFinalizationDialog: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    doAutofill: PropTypes.func.isRequired,
  };

  static defaultProps = {
    editorMode: null,
  };

  componentDidMount() {
    const { appStarted, editorMode } = this.props;
    if (appStarted && editorMode === editorModes.main) {
      this.run();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ appStarted, editorMode }) {
    if (this.props.appStarted && this.props.editorMode === editorModes.main) {
      return;
    }

    if (
      (!this.props.appStarted || this.props.editorMode === editorModes.init) &&
      appStarted &&
      editorMode === editorModes.main
    ) {
      this.run();
    }
  }

  run = () => {
    const { apply, delay } = this.props;

    if (!apply) {
      return;
    }

    if (delay > 0) {
      setTimeout(this.openModal, delay);
    } else {
      this.openModal();
    }
  };

  openModal = () => {
    const { autofillModalLocale } = this.props;
    const { title, text, cancelButtonText, okButtonText } = autofillModalLocale;
    const parameters = {
      params: {
        editType: AUTOFILL_EDIT_TYPE,
      },
      content: {
        Header: title,
        Text1: text,
        Button1: cancelButtonText,
        Button2: okButtonText,
        Icon: icon,
      },
      onFinish: this.onFinish,
    };

    this.props.showFinalizationDialog(parameters);
  };

  onFinish = () => {
    const { activeElementId, loadingModalLocale, params } = this.props;

    if (activeElementId) {
      this.props.setActiveElement(activeElementId, false);
    }

    this.props.doAutofill(params.fromProject);
    this.props.openModal(DIALOG_MODAL, {
      size: constants.SIZE_MD,
      modalType: constants.PROCESSING,
      title: loadingModalLocale.title,
    });
  };

  render() {
    return null;
  }
}
