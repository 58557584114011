import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showFindAnotherFormButton } from 'jsfcore/store/modules/viewport';
import { renderScenario } from '../scenariosUtils';

// Find Another Form (Button show)
// "action": "find_form.button.show",  идентификатор действия: показать кнопку Find Another Form
// "apply": true,  применять ли данное действие
// "runOnce": true,  запустить единоразово
// "delay": 500,  задержка перед выполнением, (ms)
// "onShow":[],  событие показа кнопки, по которому запускается вложенная цепочка действий
// "onClick":[]  событие клика кнопки, по которому запускается вложенная цепочка действий

@connect(
  (state) => {
    return {
      triggerFindAnotherFormClick: state.events.triggerFindAnotherFormClick,
    };
  }, {
    showFindAnotherFormButton,
  },
)
export default class FindFormButtonShow extends Component {
  static propTypes = {
    // action: PropTypes.oneOf(['find_form.button.show']).isRequired,
    apply: PropTypes.bool.isRequired,
    // runOnce: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onShow: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    onClick: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,

    showFindAnotherFormButton: PropTypes.func.isRequired,
    triggerFindAnotherFormClick: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { buttonShowed: false, clickStack: [] };
  }

  componentDidMount() {
    if (!this.props.apply) {
      return;
    }

    const fun = () => {
      this.props.showFindAnotherFormButton();
      this.setState({ buttonShowed: true });
    };

    if (this.props.delay > 0) {
      setTimeout(fun, this.props.delay);
    } else {
      fun();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ triggerFindAnotherFormClick }) {
    if (
      triggerFindAnotherFormClick &&
      triggerFindAnotherFormClick !== this.props.triggerFindAnotherFormClick
    ) {
      this.setState((prevState) => {
        return {
          clickStack: [
            ...prevState.clickStack,
            ...this.props.onClick,
          ],
        };
      });
    }
  }

  render() {
    const { onShow } = this.props;
    if (this.state.buttonShowed && onShow.length > 0) {
      return (
        <div>
          <div>{onShow.map(renderScenario)}</div>
          <div>{this.state.clickStack.map(renderScenario)}</div>
        </div>
      );
    }

    return null;
  }
}
