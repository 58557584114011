import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResizeDetector from 'element-resize-detector';
import { updateWorkspace } from '../../store/modules/viewport';
import { selectors } from '../..';

/**
 * Новый компонент, за основу взят WorkspaceSize из SNFiller'a
 * packages/snfiller/src/components/Workspace/WorkspaceSize.js
 *
 * Отличия:
 * Есть 2 возможные причины изменения workspaceSize
 * - Ресайз окна браузера
 * - Изменение размеров из-за изменения состояния системы
 *
 * Раньше все изменения попадали в redux через debounce в обоих случаях
 * Это приводило к задержкам при открытии-закрытии панелек внутри
 * редактора.
 *
 * Это версия компонента - посылает апдейт в redux моментально, если
 * не менялся размер body.
 * А если менятся, с задержкой.
 *
 * Скорее всего этот компонент можно без правок использовать в SNF
 */
@connect(
  null, {
    updateWorkspace,
  },
)
export default class WorkspaceSize extends Component {
  static propTypes = {
    getRef: PropTypes.func.isRequired,
    children: PropTypes.element,
    updateWorkspace: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: null,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    if (__CLIENT__) {
      this.onResizeWorkspace();
      this.delegateEvents();
    }
  }

  getWorkspaceRect = () => {
    const workspaceRef = this.props.getRef();

    // we need workspace size without scrollbars
    // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements
    const { clientWidth, clientHeight } = workspaceRef;

    // если горизонтальный скролл есть - мы должны добавить его к left

    // we can not destructure 'clientRect' via '...' operator,
    // so use every single prop separately
    const { x, y, top, right, bottom, left } = workspaceRef.getBoundingClientRect();
    return {
      x,
      y,
      top,
      right,
      bottom,
      left,
      width: clientWidth,
      height: clientHeight,
    };
  };

  getBodySizeInRedux = () => {
    return selectors.base.getBody(this.context.store.getState());
  };

  delegateEvents() {
    const workspaceRef = this.props.getRef();
    const detector = new ResizeDetector({ strategy: 'scroll' });
    detector.listenTo(workspaceRef, this.onResizeWorkspace);
  }

  isBodyRectEqual = (bodyRect) => {
    const body = this.getBodySizeInRedux();
    return (
      bodyRect.width === body.width &&
      bodyRect.height === body.height
    );
  };

  update = (bodyRect) => {
    this.props.updateWorkspace(this.getWorkspaceRect(), bodyRect);
  };

  onResizeWorkspace = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const bodyRect = document.body.getBoundingClientRect();

    if (this.isBodyRectEqual(bodyRect)) {
      this.update(bodyRect);
    } else {
      this.timeout = setTimeout(() => {
        this.update(bodyRect);
      }, 200);
    }
  };

  render() {
    return this.props.children;
  }
}
