import {
  isFillable,
  isPicture,
  isSignature,
  isFilledPictureElement,
  isFilledSignatureElement,
  isDate,
  isCells,
  isNumber,
  isCheckmarkElement as isCheckmark,
  isDropdownElement,
  isRadioElement,
} from 'jsfcore/store/helpers/functions';

import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { selectors, thunks } from 'jsfcore';

import {
  isModalVisible as getIsModalVisible,
  isModalItemsToRender as getIsModalItemsToRender,
  isPopupVisible,
  getDefaultTool,
} from 'jsfcore/store/helpers/stateToProps';

import {
  stopEvent,
  hasPlusButton,
  getIsInputOrTextareaElement,
} from 'jsfcore/helpers/utils';

import {
  setFillablePictureSelectingId,
  onOkClicked,
  zoomInByKeyboard,
  zoomOutByKeyboard,
} from 'jsfcore/store/modules/events';

import {
  wizard,
  managers,
  comments,
  fConstructor,
  dispatchAction,
  getLazy,
} from '@pdffiller/jsf-lazyload';

import {
  cancellableOpts,
} from 'jsfcore/store/modules/undoRedo';

import {
  undo,
  redo,
} from 'jsfcore/store/actions/undoRedo/undoRedo';

import {
  activateTool,
  updateElement,
  removeElement,
  trackPoint,
} from 'ws-editor-lib/actions';

import { thisDevice } from '@pdffiller/jsf-useragent';

import {
  elemTypes,
  elemSubTypes,
  isTextToolBasedElement,
  isActivateTextOnOkType,
} from 'jsfcore/helpers/elemTypes';

import {
  getIsPressedMetaKey,
  textToolArrangements,
  keyCodesMap,
} from 'jsfcore/helpers/const';

import {
  goToLeftConditions,
  goToRightConditions,
} from 'jsfcore/components/Tools/TextTool/utils/textToolCheckConditions';

import {
  getCaretPosition,
} from 'jsfcore/components/Tools/TextTool/utils/textToolUtils';

import {
  getCaretContentEditable,
} from 'jsfcore/components/Tools/CellsTool/cellsToolUtils';

import {
  actions as conditionsActions,
  selectors as conditionsSelectors,
} from '@pdffiller/jsf-conditions';

import { isDateInputActive } from '@pdffiller/datepicker';

const isValidContext = (context) => {
  return (context &&
    context.store && context.store.dispatch && context.store.getState
  ) || false;
};

const isInvalidContextMessage = 'keyboardController: Context is not valid';

const targetIsInput = (event) => {
  return (
    event.target.nodeName === 'TEXTAREA' || event.target.nodeName === 'INPUT'
  );
};

// Браузеры которые по backspace выполняют переход на предыдущую страницу
const isBrowserOnBackSpaceGoBack = () => {
  return (
    thisDevice.isInternetExplorer11 ||
    thisDevice.isEdgeDesktop ||
    thisDevice.isFirefoxDesktop
  );
};

const isEditedFormElement = (event) => {
  if (event.target.disabled || event.target.readOnly) {
    return false;
  }

  if (
    event.target.nodeName.toLowerCase() === 'textarea' ||
    event.target.nodeName.toLowerCase() === 'input' ||
    event.target.getAttribute('contenteditable')
  ) {
    return true;
  }

  return false;
};

const stopHistoryBack = (event) => {
  if (
    (!isEditedFormElement(event)) &&
    isBrowserOnBackSpaceGoBack()
  ) {
    stopEvent(event);
  }
};

const emptyFunc = () => {};

const getNextElementIndex = (shiftDirection, { fillableElements, activeElement }) => {
  const activeElementIndex = fillableElements.findIndex((element) => {
    return element.id === activeElement.id;
  });

  const nextElementIndex = activeElementIndex + shiftDirection;
  const elementsCount = fillableElements.length;

  if (nextElementIndex < 0) {
    return elementsCount - 1;
  }

  if (nextElementIndex === elementsCount) {
    return 0;
  }

  return nextElementIndex;
};


export default class KeyboardController {
  constructor(context) {
    if (!isValidContext(context)) {
      throw new Error(isInvalidContextMessage);
    }

    this.store = context.store;
    this.dispatch = context.store.dispatch;
    this.eventsDelegated = false;

    this.onKeyDownMap = {
      [keyCodesMap.enter]: this.onEnter,
      [keyCodesMap.del]: this.onDel,
      [keyCodesMap.tab]: this.onTab,
      [keyCodesMap.esc]: this.onEsc,
      [keyCodesMap.backspace]: this.onBackspace,
      [keyCodesMap.left]: this.onLeftUp,
      [keyCodesMap.up]: this.onLeftUp,
      [keyCodesMap.right]: this.onRightDown,
      [keyCodesMap.down]: this.onRightDown,
      [keyCodesMap.z]: this.onZ,
      [keyCodesMap.s]: this.onS,
      [keyCodesMap.other]: this.onOther,

      [keyCodesMap.plus[0]]: this.onPlusMinus,
      [keyCodesMap.plus[1]]: this.onPlusMinus,
      [keyCodesMap.plus[2]]: this.onPlusMinus,

      [keyCodesMap.minus[0]]: this.onPlusMinus,
      [keyCodesMap.minus[1]]: this.onPlusMinus,
      [keyCodesMap.minus[2]]: this.onPlusMinus,
    };
    this.onKeyDownBodyMap = {
      [keyCodesMap.space]: this.onSpace,
    };
    this.delegateEvents();
    this.onDoneButtonClickSimulate = thunks.onDoneButtonOptionClickFactory();
  }

  destroy() {
    this.undelegateEvents();
  }

  delegateEvents() {
    document.addEventListener('keydown', this.onKeyDown);
    document.body.addEventListener('keydown', this.onKeyDownBody);
    this.eventsDelegated = true;
  }

  undelegateEvents() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.body.removeEventListener('keydown', this.onKeyDownBody);
    this.eventsDelegated = false;
  }

  getStateData = (state = this.store.getState()) => {
    const activeElement = selectors.elements.getActiveElement(state);
    const isCheckmarkOrRadioElement = isCheckmark(activeElement) || isRadioElement(activeElement);

    return {
      activePageId: selectors.base.getActivePageId(state),
      isModalVisible: getIsModalVisible(state),
      isModalItemsToRender: getIsModalItemsToRender(state),
      isPopupVisible: isPopupVisible(state),
      activeElement,
      isFillableElement: isFillable(activeElement),
      isPictureElement: isPicture(activeElement),
      isSignatureElement: isSignature(activeElement),
      isCheckmarkOrRadioElement,
      pageChanging: state.events.pageChanging,
      isFConstructorShown: state.viewport.isFConstructorShown,
      defaultTool: getDefaultTool(state),
      isDateElement: isDate(activeElement),
      activeTool: state.ws.activeTool,
      isPAConstructorShown: state.viewport.isPAConstructorShown,
      isFConstructorOrderShown:
        state.fConstructor && state.fConstructor.isFConstructorOrderShown,
      isFConstructorPreviewShown:
        state.fConstructor && state.fConstructor.isFConstructorPreviewShown,
      isUndoAvailable: selectors.isUndoAvailable(state),
      isRedoAvailable: selectors.isRedoAvailable(state),
      fillableElements: selectors.getFillableElements(state),
      elements: selectors.base.getElements(state),
      comments: selectors.comments.getState(state),
      isDropdownToolOpen: selectors.base.getIsDropdownToolOpen(state),
      isDbFieldNameFocused: selectors.base.getIsDbFieldNameFocused(state),
      isPageAttributes: selectors.mode.isPageAttributes(state),
      isVersionsShown: selectors.base.getIsVersionsShown(state),
      isActiveChoiseByClickingMode: conditionsSelectors.getChoiseByClickingModeIsActive(state),
    };
  };

  getKeyDownCallback = (keyCode) => {
    return this.onKeyDownMap[keyCode] || this.onKeyDownMap[keyCodesMap.other];
  };

  getKeyDownBodyCallback = (keyCode) => {
    return this.onKeyDownBodyMap[keyCode] || emptyFunc;
  };

  onKeyDown = (event) => {
    return this.getKeyDownCallback(event.keyCode)(event);
  };

  onKeyDownBody = (event) => {
    return this.getKeyDownBodyCallback(event.keyCode)(event);
  };

  // TODO: comment
  onSpace = (event, slct = this.getStateData()) => {
    if (slct.isModalVisible || slct.activeElement || targetIsInput(event)) {
      return;
    }

    stopEvent(event);
  };

  // TODO: comment
  // We need process 'Enter' keydowns here, because IE does process it inside child components
  // very strange (sometimes events are fired, sometimes - not, it's IE feature)
  onEnter = (event, slct = this.getStateData()) => {
    if (slct.activeElement && isDropdownElement(slct.activeElement)) {
      const isPressedMetaKey = getIsPressedMetaKey(event);

      if (isPressedMetaKey) {
        stopEvent(event);
        // setTimeout is needed because of onChange in SelectDropdown does not have time to execute
        setTimeout(() => {
          dispatchAction(wizard.actions.toggleWizardNext);
        }, 0);
      }
      return;
    }

    if (slct.isModalVisible || !slct.isFillableElement) {
      return;
    }

    if (slct.isFConstructorShown && !slct.isFConstructorPreviewShown) {
      return;
    }

    if (slct.isPictureElement) {
      if (isFilledPictureElement(slct.activeElement)) {
        dispatchAction(wizard.actions.toggleWizardNext);
      } else {
        this.dispatch(setFillablePictureSelectingId(slct.activeElement.id));
        dispatchAction(managers.actions.openImageManager, {});
      }
    }

    if (slct.isSignatureElement) {
      if (isFilledSignatureElement(slct.activeElement)) {
        dispatchAction(wizard.actions.toggleWizardNext);
      } else {
        dispatchAction(wizard.actions.triggerSelectSignatureClick);
      }
    }

    if (slct.isCheckmarkOrRadioElement) {
      stopEvent(event);
      dispatchAction(wizard.actions.toggleWizardNext);
    }

    if (
      slct.activeElement && isTextToolBasedElement(slct.activeElement) && (
        isDate(slct.activeElement) ||
        isNumber(slct.activeElement) ||
        isCells(slct.activeElement)
      )
    ) {
      stopEvent(event);
      // JSF-1594
      event.stopImmediatePropagation();
      dispatchAction(wizard.actions.toggleWizardNext);
    }
  };

  // TODO: comment
  //
  // NOTE:
  // before refactoring delete || backspace
  // after refactoring only delete button
  //
  // const isDelKeyCode = event.keyCode === 46 || event.keyCode === 8;
  // if (isDelKeyCode) this.props.onDelKeydown(event);
  onDel = (event, slct = this.getStateData()) => {
    if (slct.isModalVisible) {
      return;
    }

    if (slct.isFConstructorShown && !slct.isFConstructorPreviewShown) {
      // check for any input fields in focus, to prevent deletion while editing
      const isInputOrTextareaElement = getIsInputOrTextareaElement(event.target);
      if (slct.activeElement && !isInputOrTextareaElement) {
        stopEvent(event);
        this.dispatch(thunks.remove(slct.activeElement.id, cancellableOpts));
        this.dispatch(thunks.setActiveElement(slct.activeElement.id, false));
      }
      return;
    }

    if (slct.isCheckmarkOrRadioElement && slct.isFillableElement) {
      stopEvent(event);
      this.dispatch(updateElement(
        slct.activeElement.id,
        { checked: false },
        cancellableOpts,
      ));
      return;
    }

    if (
      slct.activeElement && (
        isFilledPictureElement(slct.activeElement) ||
        isFilledSignatureElement(slct.activeElement) ||
        (slct.isDateElement && slct.isFillableElement)
      )
    ) {
      this.dispatch(removeElement(slct.activeElement.id, cancellableOpts));
    }

    if (
      slct.activeElement &&
      !isTextToolBasedElement(slct.activeElement) &&
      !isFillable(slct.activeElement) &&
      !isFilledPictureElement(slct.activeElement) &&
      !isFilledSignatureElement(slct.activeElement)
    ) {
      this.dispatch(removeElement(slct.activeElement.id, cancellableOpts));
      this.dispatch(thunks.setActiveElement(slct.activeElement.id, false));

      if (slct.activeTool && isActivateTextOnOkType(slct.activeTool.type)) {
        const { type, subType } = slct.defaultTool;
        this.dispatch(activateTool(type, subType));
      }
    }

    const isTextInputTarget =
      event.target.nodeName.toLowerCase() === 'textarea' ||
      event.target.nodeName.toLowerCase() === 'input' ||
      event.target.getAttribute('contenteditable');

    if (!slct.activeElement && !slct.isPAConstructorShown && !isTextInputTarget) {
      event.preventDefault();
    }
  };

  // TODO: comment
  onTab = (event, slct = this.getStateData()) => {
    if (slct.isModalVisible) {
      return;
    }

    if (!slct.isDbFieldNameFocused) {
      event.preventDefault();
    }

    if (!slct.isFillableElement) {
      return;
    }

    // Перемещение по полям в режиме конструктора
    if (slct.isFConstructorShown && !slct.isFConstructorPreviewShown) {
      if (!slct.isDbFieldNameFocused) {
        stopEvent(event);
      }

      event.stopImmediatePropagation(); // JSF-1594
      const { fillableElements, activeElement } = slct;

      // Shift+Tab or Tab
      const shiftDirection = event.shiftKey
        ? -1
        : 1;
      const nextActiveElementIndex = getNextElementIndex(shiftDirection, slct);
      const nextActiveElement = fillableElements[nextActiveElementIndex];

      if (nextActiveElement.pageId !== activeElement.pageId) {
        // change page and set active element
        this.dispatch(thunks.changePage(
          nextActiveElement.pageId,
          activeElement.pageId,
          nextActiveElement.id,
        ));
      } else {
        this.dispatch(thunks.setActiveElement(nextActiveElement.id, true));
      }

      return;
    }

    if (isTextToolBasedElement(slct.activeElement)) {
      stopEvent(event);

      // JSF-1594
      // При переходе с cells (1) на cells (2),
      // Поле 1 теряет фокус, поле 2 получает фокус. Event успевает сначала
      // сработать в этой функции поля 1. А затем в этой функции поля 2.
      event.stopImmediatePropagation();

      // Shift+Tab
      if (event.shiftKey) {
        if (!thisDevice.isIOS) {
          dispatchAction(wizard.actions.toggleWizardPrev);
          this.trackUserFieldActivation();
        }

      // Tab
      } else if (!thisDevice.isIOS) {
        dispatchAction(wizard.actions.toggleWizardNext);
        this.trackUserFieldActivation();
      }
      return;
    }

    stopEvent(event);
    event.stopImmediatePropagation(); // JSF-1594
    dispatchAction(
      event.shiftKey
        ? wizard.actions.toggleWizardPrev
        : wizard.actions.toggleWizardNext,
    );
    this.trackUserFieldActivation();
  };

  // TODO: comment
  onEsc = (event, slct = this.getStateData()) => {
    if (slct.isModalVisible) {
      return;
    }

    const { editCommentId, activeCommentId, deleteCommentId } = slct.comments;

    // comment edit
    if (editCommentId !== false) {
      dispatchAction(comments.actions.setEditComment, false, { cancel: true });
    }

    // comment delete
    if (deleteCommentId !== false) {
      dispatchAction(comments.actions.setDeleteComment, false);
    }

    // deactivate reply
    if (activeCommentId !== false && editCommentId === false) {
      // reset addCommentMessage
      dispatchAction(comments.actions.setAddCommentMessage, '');
      // then deactivate comment without reply creation
      dispatchAction(comments.actions.setActiveComment, false);
    }

    if (slct.isFConstructorShown && !slct.isFConstructorPreviewShown) {
      if (slct.isActiveChoiseByClickingMode) {
        // Выключить choiseByClicking если нажали на escape
        this.dispatch(conditionsActions.choiseByClicking.disable());
        return;
      }

      if (slct.activeElement) {
        this.dispatch(thunks.setActiveElement(slct.activeElement.id, false));
      } else {
        this.dispatch(activateTool(elemTypes.fctool, elemSubTypes.none));
      }
      return;
    }

    if (slct.activeElement) {
      this.dispatch(onOkClicked());
    }

    const { type, subType } = slct.defaultTool;
    this.dispatch(activateTool(type, subType));
  };

  // TODO: comment
  onBackspace = (event, slct = this.getStateData()) => {
    stopHistoryBack(event);

    if (
      slct.isModalVisible ||
      (slct.isDateElement && isDateInputActive())
    ) {
      return;
    }

    if (slct.isFConstructorShown && !slct.isFConstructorPreviewShown) {
      if (thisDevice.isMacOSX) {
        const isInputOrTextareaElement = getIsInputOrTextareaElement(event.target);
        if (slct.activeElement && !isInputOrTextareaElement) {
          stopEvent(event);
          this.dispatch(thunks.remove(slct.activeElement.id, cancellableOpts));
          this.dispatch(thunks.setActiveElement(slct.activeElement.id, false));
        }
      }
      return;
    }

    if (
      slct.activeElement && (
        isFilledPictureElement(slct.activeElement) ||
        isFilledSignatureElement(slct.activeElement)
      )
    ) {
      this.dispatch(removeElement(slct.activeElement.id, cancellableOpts));
      if (isBrowserOnBackSpaceGoBack()) {
        stopEvent(event);
      }
    }

    if (
      slct.activeElement && (
        (slct.isDateElement && slct.isFillableElement) ||
        (
          isDropdownElement(slct.activeElement) &&
          !slct.isDropdownToolOpen
        )
      )
    ) {
      this.dispatch(
        updateElement(slct.activeElement.id, {
          text: '',
        }, cancellableOpts),
      );
      dispatchAction(wizard.actions.toggleWizardNext);
      if (isBrowserOnBackSpaceGoBack()) {
        stopEvent(event);
      }
    }

    if (
      slct.activeElement &&
      !isTextToolBasedElement(slct.activeElement) &&
      !isFillable(slct.activeElement) &&
      !isFilledPictureElement(slct.activeElement) &&
      !isFilledSignatureElement(slct.activeElement)
    ) {
      this.dispatch(removeElement(slct.activeElement.id, cancellableOpts));
      this.dispatch(thunks.setActiveElement(slct.activeElement.id, false));

      if (slct.activeTool && isActivateTextOnOkType(slct.activeTool.type)) {
        const { type, subType } = slct.defaultTool;
        this.dispatch(activateTool(type, subType));
      }
      if (isBrowserOnBackSpaceGoBack()) {
        stopEvent(event);
      }
    }

    const { target } = event;
    const isTextInputTarget =
      target.nodeName.toLowerCase() === 'textarea' ||
      target.getAttribute('contenteditable') ||
      target.className === 'Select-input';

    // в FF и IE Backspace - это шорткат для браузерного 'Назад'.
    // Может быть такое, что пользователь будет давить Backspace, не дожидаясь того чтобы
    // курсор встал в текстовое поле (JSF-1208). Так что превентим такой момент
    if (
      isTextToolBasedElement(slct.activeElement) &&
      !isTextInputTarget &&
      !slct.isDropdownToolOpen &&
      isBrowserOnBackSpaceGoBack()
    ) {
      stopEvent(event);
    }
  };

  getCaretPosition = (activeElement, event) => {
    if (activeElement.template.arrangement === textToolArrangements.cells) {
      return getCaretContentEditable(event.target);
    }

    return getCaretPosition(event.target);
  };

  getContentSize = (activeElement, event) => {
    if (activeElement.template.arrangement === textToolArrangements.cells) {
      return event.target.innerText.length;
    }

    if (!event.target.value) {
      return 0;
    }

    return event.target.value.length;
  };

  // TODO: comment
  onLeftUp = (event, slct = this.getStateData()) => {
    if (
      slct.activeElement && isDropdownElement(slct.activeElement) &&
      event.keyCode === keyCodesMap.up
    ) {
      return;
    }

    if (
      slct.isModalVisible || !slct.isFillableElement ||
      (slct.isFConstructorShown && !slct.isFConstructorPreviewShown) ||
      (slct.isDateElement && isDateInputActive())
    ) {
      return;
    }

    if (
      isTextToolBasedElement(slct.activeElement) &&
      !goToLeftConditions({
        caret: this.getCaretPosition(slct.activeElement, event),
        isDate: isDate(slct.activeElement),
        isFillable: slct.isFillableElement,
        isSafariDesktop: thisDevice.isSafariDesktop,
      })
    ) {
      return;
    }

    stopEvent(event);
    event.stopImmediatePropagation(); // JSF-1594
    dispatchAction(wizard.actions.toggleWizardPrev);
    this.trackUserFieldActivation();
  };

  // TODO: comment
  onRightDown = (event, slct = this.getStateData()) => {
    if (
      slct.activeElement && isDropdownElement(slct.activeElement) &&
      event.keyCode === keyCodesMap.down
    ) {
      return;
    }

    if (
      slct.isModalVisible || !slct.isFillableElement ||
      (slct.isFConstructorShown && !slct.isFConstructorPreviewShown) ||
      (slct.isDateElement && isDateInputActive())
    ) {
      return;
    }

    if (
      isTextToolBasedElement(slct.activeElement) &&
      !goToRightConditions({
        caret: this.getCaretPosition(slct.activeElement, event),
        size: this.getContentSize(slct.activeElement, event),
        isDate: isDate(slct.activeElement),
        isFillable: slct.isFillableElement,
      })
    ) {
      return;
    }

    stopEvent(event);
    event.stopImmediatePropagation(); // JSF-1594
    dispatchAction(wizard.actions.toggleWizardNext);
    this.trackUserFieldActivation();
  };

  onZ = (event, slct = this.getStateData()) => {
    if (slct.isModalVisible) {
      return;
    }

    const isPressedMetaKey = getIsPressedMetaKey(event);

    if (isPressedMetaKey) {
      event.preventDefault();
      if (event.shiftKey) {
        if (slct.isRedoAvailable) {
          this.dispatch(redo());
        }
      } else if (slct.isUndoAvailable) {
        this.dispatch(undo());
      }
    }
  };

  onS = (event, slct = this.getStateData()) => {
    const isPressedMetaKey = getIsPressedMetaKey(event);

    if (isPressedMetaKey) {
      if (
        slct.isModalVisible ||
        slct.isModalItemsToRender ||
        slct.isPopupVisible ||
        slct.isPageAttributes ||
        slct.isVersionsShown
      ) {
        event.preventDefault();
        return;
      }

      if (slct.isFConstructorShown) {
        event.preventDefault();
        dispatchAction(fConstructor.actions.hideConstructor);
        return;
      }

      this.dispatch(this.onDoneButtonClickSimulate)(event);
    }
  };

  // TODO: Конкретизировать кнопки
  onOther = (event, slct = this.getStateData()) => {
    if (
      slct.isModalVisible ||
      !slct.isFillableElement ||
      slct.isFConstructorShown ||
      event.altKey ||
      event.metaKey ||
      event.ctrlKey ||
      event.shiftKey
    ) {
      return;
    }

    if (slct.isCheckmarkOrRadioElement && slct.activeElement.pageId === slct.activePageId) {
      stopEvent(event);
      this.dispatch(updateElement(slct.activeElement.id, {
        checked: !get(slct.activeElement, 'content.checked', false),
      }, cancellableOpts));
    }
  };

  onZoomCtrlPlusMinus = (event) => {
    this.dispatch(
      hasPlusButton(event.keyCode)
        ? zoomInByKeyboard()
        : zoomOutByKeyboard(),
    );
  };

  onZoomCtrlPlusMinusDebounced = debounce(this.onZoomCtrlPlusMinus, 100);

  onPlusMinus = (event, slct = this.getStateData()) => {
    if (slct.isModalVisible) {
      return;
    }

    const isPressedMetaKey = getIsPressedMetaKey(event);

    if (isPressedMetaKey) {
      event.preventDefault();
      if (thisDevice.isFirefoxDesktop) {
        this.onZoomCtrlPlusMinusDebounced(event);
      } else {
        this.onZoomCtrlPlusMinus(event);
      }
    }
  }

  trackUserFieldActivation() {
    const wizardTrackPoints = getLazy(wizard.objects.trackPoints, null);
    if (wizardTrackPoints) {
      this.dispatch(trackPoint(wizardTrackPoints.FIELD_ACTIVATED_WITHOUT_WIZARD));
    }
  }
}
